<template>
  <component v-if="hasMorePropertiesInCity" :is="tag" class="more-properties-base">
    <h2>{{ getMorePropertiesInCityTitle }}</h2>
    <div class="more-properties-listing">
      <destination-card
        v-for="property in getMorePropertiesInCity"
        :key="property.id"
        v-bind="property"
        @destination-card-click="handleDestinationCardClick(property)"
      />
    </div>
  </component>
</template>

<script>
import DestinationCard from '~/components/common/DestinationCard';
import { UrlBuilder } from '~/lib/url';
import { findPropertyTypeByKey } from '~/lib/propertyTypes';
import { DEFAULT_CURRENCY } from '~/config/currencies';
export default {
  name: 'MorePropertiesInCity',
  components: {
    DestinationCard,
  },
  props: {
    tag: {
      type: String,
      required: false,
      default: 'div',
    },
    cityName: {
      type: String,
      required: true,
    },
    propertyTypeObj: {
      type: Object,
      required: true,
    },
    propertyList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    hasMorePropertiesInCity() {
      return this.propertyList?.length;
    },
    getMorePropertiesInCity() {
      return this.propertyList.map((property) => {
        const propertyImage = property?.image?.small || null;
        const propertyImageUrl = propertyImage ? new UrlBuilder(propertyImage).addProtocol() : null;

        const actualPropertyTypeObj = findPropertyTypeByKey(property?.type);
        const locationData = {
          propertyTypeSlug: this.$t(actualPropertyTypeObj.slug),
          urlFriendlyContinent: property.urlFriendlyContinent,
          urlFriendlyCountry: property.urlFriendlyCountry,
          urlFriendlyCity: property.urlFriendlyCity,
        };
        const propertyData = {
          id: property.id,
          urlFriendlyName: property.urlFriendlyName,
        };

        const propSharedMinPrice = property?.sharedMinPrice?.value || 0;
        const propPrivateMinPrice = property?.privateMinPrice?.value || 0;
        const propPriceCurrency =
          property?.sharedMinPrice?.currency || property?.privateMinPrice?.currency || DEFAULT_CURRENCY.code;
        const propPrices = [parseFloat(propSharedMinPrice), parseFloat(propPrivateMinPrice)].filter(
          (price) => price > 0
        );

        return {
          id: property.id,
          name: property.name,
          image: propertyImageUrl,
          rating: {
            score: property?.avgRating,
            showReviews: false,
            showKeyword: false,
          },
          price: {
            value: Math.min(...propPrices),
            currency: propPriceCurrency,
          },
          link: this.$url.getPropertyUrl(locationData, propertyData),
        };
      });
    },
    getMorePropertiesInCityTitle() {
      return this.$t('t_MOREPROPERTIESINLOCATION', {
        PROPERTYTYPES: this.$t(this.propertyTypeObj.transcode).toLowerCase(),
        CITY: this.cityName,
      });
    },
  },
  methods: {
    handleDestinationCardClick(property) {
      this.$emit('more-properties-card-click', property);
    },
  },
};
</script>

<style scoped lang="scss">
.more-properties-base {
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-l;

  h2 {
    color: $wds-color-ink-dark;
    @include title-4-bld;
  }

  .more-properties-listing {
    display: flex;
    flex-direction: column;
    gap: $wds-spacing-m;
  }

  @include tablet-large {
    .more-properties-listing {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  @include desktop {
    .more-properties-listing {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
</style>
