<template>
  <div
    :class="['rating-summary-container', size, { clickable }]"
    :aria-label="$t('t_RATING' + getRatingTranslation(rating.total, rating.isNew)) + ', ' + getTotalReviewsTranscode"
  >
    <rating v-if="!rating.isNew && Number(rating.total) > 0" :rating="ratingRefine" :size="size" />
    <div v-if="showKeyword || showTotal || showTotalWithIcon" :class="rating.color" class="summary">
      <div v-if="showKeyword" class="keyword">
        <span>{{ $t('t_RATING' + getRatingTranslation(rating.total, rating.isNew)) }}</span>
      </div>
      <div v-if="showTotal" class="reviews">{{ getTotalReviewsTranscode }}</div>
      <div v-if="showTotalWithIcon" class="reviews">
        <svg-icon name="chat" class="reviews-icon" />
        {{ this.rating.totalReviews }}
      </div>
    </div>
  </div>
</template>

<script>
import Rating from '~/components/hwrwd/common/Ratings/Rating';
import RatingMixin from '~/components/hwrwd/common/Ratings/mixin';
export default {
  name: 'RatingSummary',
  mixins: [RatingMixin],
  components: {
    Rating,
  },
  props: {
    rating: {
      type: Object,
      required: true,
    },
    showKeyword: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTotal: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTotalWithIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'big',
      validator: (value) => {
        return ['big', 'medium', 'small'].indexOf(value) !== -1;
      },
    },
    clickable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ratingRefine() {
      return {
        ...this.rating,
        total: this.$formatter.formatRating(this.rating.total),
        scoreMessage: this.$formatter.formatRating(this.rating.scoreMessage),
      };
    },

    getTotalReviewsTranscode() {
      let transcode = this.rating.totalReviews === 1 ? 't_NUMBEROFREVIEW' : 't_NUMBEROFREVIEWS';
      return this.$t(transcode, { NUMBEROFREVIEWS: this.rating.totalReviews });
    },
  },
  methods: {
    onClick() {
      if (this.clickable) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-summary-container {
  display: inline-flex;
  gap: $wds-spacing-s;
  text-decoration: none;
  width: 100%;

  &.clickable:hover {
    cursor: pointer;
  }

  .summary {
    color: $wds-color-ink;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    &.orange {
      color: $wds-color-orange;
    }

    .keyword {
      @include body-1-bld;
      display: flex;
      flex: 1;
      align-items: center;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .reviews {
      font-size: $wds-font-size-body-3;
      display: flex;
      flex: 1;
      line-height: 1;
      align-items: center;

      @include truncate-text;

      .reviews-icon {
        margin-right: $wds-spacing-xs;
        width: 16px;
        height: 16px;
      }
    }
  }

  &.small {
    ::v-deep .score {
      margin-top: $wds-spacing-xxs;
    }
  }

  &.medium,
  &.small {
    .keyword {
      font-size: $wds-font-size-body-3;
      line-height: $wds-line-height-body-3;
    }

    .reviews {
      font-size: $wds-font-size-body-3;
    }
  }
}
</style>
