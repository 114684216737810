<template>
  <button
    :class="['form-checkbox-button', { checked }, { error }]"
    @click="onClick"
    @focus="$emit('CheckBoxButtonFocus')"
    @keypress.enter="onClick"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'CheckBoxButton',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object],
      required: false,
      default: null,
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit('buttonChecked', { checked: !this.checked, value: this.value || this.label });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-checkbox-button {
  height: wds-rem(42px);
  background: $wds-color-white;
  border-radius: wds-rem(3px);
  display: grid;
  align-items: center;
  cursor: pointer;
  text-align: center;
  font-size: $wds-font-size-body-2;
  color: $wds-color-ink-dark;
  font-weight: normal;
  border: wds-rem(3px) solid transparent;

  &.checked {
    color: $wds-color-orange;
    border: wds-rem(3px) solid $wds-color-orange;
  }

  &.error {
    background-color: $wds-color-red-lightest;
  }
}
</style>
