<template>
  <div :class="['score', rating.color, size]" :aria-label="`${$t('t_RATING')}: ${rating.scoreMessage}`">
    {{ rating.scoreMessage }}
  </div>
</template>

<script>
export default {
  name: 'Rating',
  props: {
    rating: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'big',
      validator: (value) => {
        return ['big', 'medium', 'small'].indexOf(value) !== -1;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.score {
  width: wds-rem(45px);
  height: wds-rem(34px);
  font-size: $wds-font-size-title-5;
  text-align: center;
  font-weight: bold;
  color: $wds-color-white;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $wds-color-ink;
  border-radius: wds-rem(4px);
  flex-shrink: 0;

  &.medium {
    width: wds-rem(40px);
    height: wds-rem(30px);
    font-size: $wds-font-size-body-1;
  }

  &.small {
    width: wds-rem(35px);
    height: wds-rem(24px);
    font-size: $wds-font-size-body-3;
  }

  &.orange {
    background-color: $wds-color-orange;
  }
}
</style>
