export const socialMediaTags = (tags) => {
  return Object.entries(tags).flatMap(([key, value]) =>
    value
      ? [
          {
            hid: key,
            name: key,
            property: key,
            content: value,
          },
        ]
      : []
  );
};
