const ratingTypes = {
  NEW: 'NEW',
  NORATING: 'NORATING',
  RATING: 'RATING',
  GOOD: 'GOOD',
  VERYGOOD: 'VERYGOOD',
  FABULOUS: 'FABULOUS',
  SUPERB: 'SUPERB',
};

export default {
  methods: {
    getRatingTranslation(rating, isNew) {
      if (isNew) {
        return ratingTypes.NEW;
      }

      if (rating === 0) {
        return ratingTypes.NORATING;
      }

      if (rating >= 9) {
        return ratingTypes.SUPERB;
      }

      if (rating >= 8) {
        return ratingTypes.FABULOUS;
      }

      if (rating >= 7) {
        return ratingTypes.VERYGOOD;
      }

      if (rating >= 6) {
        return ratingTypes.GOOD;
      }

      return ratingTypes.RATING;
    },
  },
};
