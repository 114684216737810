<template>
  <div class="descriptions-container">
    <div v-if="hasHostelworldSays" class="description-block hw-says">
      <h2 v-if="hostelworldSays.title" class="description-title">{{ hostelworldSays.title }}</h2>
      <div class="description-body" :class="{ expanded: hwSaysExpanded }" v-html="hostelworldSays.text" />
      <div v-if="hwSaysShouldExpand" class="description-toggler">
        <a href="#" @click.prevent="toggleExpandedHwSays()" @keypress.space.prevent="toggleExpandedHwSays()">
          {{ hwSaysExpanded ? $t('t_SHOWLESS') : $t('t_SHOWMORE') }}
        </a>
      </div>
    </div>

    <div v-if="hasMainDescription" class="description-block main-description">
      <h2 v-if="mainDescription.title" class="description-title">{{ mainDescription.title }}</h2>
      <div class="description-body" :class="{ expanded: descriptionExpanded }">
        <div v-html="mainDescription.text" />
        <div class="house-rules-link">
          <a href="#" @click.prevent="houseRulesClick" @keypress.enter.prevent="houseRulesClick">
            <svg-icon name="clipboard" class="house-rules-icon" />
            <span>{{ $t('t_VIEWHOUSERULES') }}</span>
          </a>
        </div>
      </div>
      <div v-if="descriptionShouldExpand" class="description-toggler">
        <a href="#" @click.prevent="toggleExpandedDescription()" @keypress.space.prevent="toggleExpandedDescription()">
          {{ descriptionExpanded ? $t('t_SHOWLESS') : $t('t_SHOWMORE') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PropertyOverview',
  props: {
    mainDescription: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    hostelworldSays: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      hwSaysShouldExpand: false,
      hwSaysExpanded: false,
      descriptionShouldExpand: true,
      descriptionExpanded: false,
    };
  },
  computed: {
    hasMainDescription() {
      return this.mainDescription?.text;
    },
    hasHostelworldSays() {
      return this.hostelworldSays?.text;
    },
  },
  mounted() {
    this.checkDescriptionHeights();
    window.addEventListener('resize', this.checkDescriptionHeights);
  },
  destroyed() {
    window.removeEventListener('resize', this.checkDescriptionHeights);
  },
  methods: {
    async toggleExpandedDescription() {
      this.descriptionExpanded = !this.descriptionExpanded;

      this.tracking = await this.$tracking.PropertyPage();
      this.tracking.onDescriptionReadMoreClick();
    },
    toggleExpandedHwSays() {
      this.hwSaysExpanded = !this.hwSaysExpanded;
    },
    checkDescriptionHeights() {
      const hwSays = document.querySelector('.hw-says .description-body');
      if (hwSays) {
        this.hwSaysShouldExpand = hwSays.scrollHeight > 200;
      }

      const mainDesc = document.querySelector('.main-description .description-body');
      if (mainDesc) {
        this.descriptionShouldExpand = mainDesc.scrollHeight > 200;
      }
    },
    houseRulesClick() {
      this.$emit('house-rules-link');
    },
  },
};
</script>

<style lang="scss" scoped>
.descriptions-container {
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-m;

  a {
    @include body-2-reg;
  }

  .description-block {
    display: flex;
    flex-direction: column;

    .description-title {
      display: block;
      color: $wds-color-ink-dark;
      padding-bottom: $wds-spacing-s;
      @include title-4-bld;
    }

    .description-body {
      max-height: wds-rem(200px);
      overflow: hidden;
      @include body-2-reg;

      &.expanded {
        max-height: initial;
      }
    }
  }

  .main-description {
    .description-body {
      display: flex;
      flex-direction: column;
      gap: $wds-spacing-m;

      .house-rules-link {
        display: flex;
        padding: $wds-spacing-m 0 $wds-spacing-xl $wds-spacing-xs;
        border-top: 1px dotted $wds-color-ink-light;

        & > a {
          display: flex;
          gap: $wds-spacing-s;
          align-items: center;

          .house-rules-icon {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .description-toggler {
      display: flex;
    }
  }
}
</style>
