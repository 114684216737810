<template>
  <a
    href="https://w5az.app.link/hwstatic-banner"
    target="_blank"
    rel="noopener noreferrer"
    :aria-label="$t('t_SOCIALBANNERTITLE')"
    :aria-description="$t('t_SOCIALBANNERTEXT')"
    :class="['hw-social-banner', { 'new-header': shouldShowNewSocialBanner }]"
  >
    <component ref="mobileBannerComponent" :is="getComponentVersion" />
  </a>
</template>

<script>
import { mapGetters } from 'vuex';
import mq from '~/mixins/mq';

export default {
  name: 'HwSocialBanner',

  mixins: [mq],

  props: {
    isVertical: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    HwSocialBannerMobile: /* istanbul ignore next */ () =>
      import(/* webpackMode: "lazy" */ '~/components/common/HwSocialBanner/mobile'),
    HwSocialBannerDesktop: /* istanbul ignore next */ () =>
      import(/* webpackMode: "lazy" */ '~/components/common/HwSocialBanner/desktop'),
    HwSocialBannerVertical: /* istanbul ignore next */ () =>
      import(/* webpackMode: "lazy" */ '~/components/common/HwSocialBanner/vertical'),
  },

  computed: {
    ...mapGetters({
      isPropertyPage: 'route/isPropertyPage',
    }),
    shouldShowNewSocialBanner() {
      return !this.isPropertyPage;
    },
    getComponentVersion() {
      return this.isVertical
        ? 'HwSocialBannerVertical'
        : this.isSmallOrMediumScreen
        ? 'HwSocialBannerMobile'
        : 'HwSocialBannerDesktop';
    },
  },
};
</script>

<style lang="scss" scoped>
.hw-social-banner {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  background-image: url('/st/images/social-banner-bg-small.jpg');

  &.new-header {
    max-width: 1600px;
    margin: 0 auto;
  }

  @include tablet {
    &.new-header {
      border-radius: $wds-border-radius-xxl;
    }
  }

  @include tablet-large {
    background-position: center center;
    background-image: url('/st/images/social-banner-bg-large.jpg');
  }

  @include desktop {
    &.new-header:focus-visible {
      box-shadow: $border-focus-default;
    }
  }
}
</style>
