<template>
  <div class="property-latest-reviews-wrapper">
    <h2>{{ $t('t_LATESTREVIEWS') }}</h2>
    <div class="property-latest-reviews-container">
      <rwd-review-card
        v-for="(review, idx) in latestReviewsList"
        :key="idx"
        v-bind="getPropertyReviewCardData(review)"
        class="property-review-card"
      />
    </div>
    <rwd-button
      color="secondary"
      class="latest-reviews-read-all"
      :full-width="isBelowMediumScreen"
      @click="triggerReadAllReviews"
    >
      {{ $t('t_VIEWALLREVIEWS') }}
    </rwd-button>
  </div>
</template>

<script>
import RwdButton from '~/components/hwrwd/inputs/Button';
import RwdReviewCard from '~/components/hwrwd/common/Reviews/ReviewCard';
import mq from '~/mixins/mq';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import { formatHumanReadableShortDate } from '~/lib/timeFromPWA';

export default {
  name: 'LatestReviewsSection',
  mixins: [mq],
  components: {
    RwdButton,
    RwdReviewCard,
  },
  props: {
    latestReviewsList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    triggerReadAllReviews() {
      this.$emit('read-all-reviews-click');
    },
    getPropertyReviewCardData(review) {
      const dateParsed = parse(review.date, 'yyyy-MM-dd', new Date());

      const reviewStayedDate = isValid(dateParsed)
        ? formatHumanReadableShortDate(dateParsed, this.$store.state.session.language.tag)
        : '';

      return {
        rating: {
          total: review.rating,
          scoreMessage: review.rating,
          color: 'orange',
        },
        review: {
          reviewText: review.text,
          reviewDate: reviewStayedDate,
        },
        author: review.author,
        size: 'small',
        reviewLink: false,
        showKeyword: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.property-latest-reviews-wrapper {
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-m;
  padding: $wds-spacing-m;

  h2 {
    color: $wds-color-ink-dark;
    @include title-4-bld;
  }

  .property-latest-reviews-container {
    display: flex;
    flex-direction: column;
    gap: $wds-spacing-m;
  }

  ::v-deep .latest-reviews-read-all {
    align-self: flex-start;
  }

  @include tablet-large {
    .property-latest-reviews-container {
      flex-direction: row;

      .property-review-card {
        flex-basis: 33%;
      }
    }
  }
}
</style>
