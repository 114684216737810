<template functional>
  <div
    v-bind="{
      ...data.attrs,
      [`${parent.$options._scopeId}`]: true,
    }"
    role="img"
    :alt="`${parent.$t('t_HWRWDFLAG')} - ${props.countryName}`"
    loading="lazy"
    :class="['flag', data.class, data.staticClass]"
    :style="{ backgroundImage: `url(https://a.hwstatic.com/hw/flags/${props.countryCode}.svg)` }"
    v-on="listeners"
  />
</template>

<script>
export default {
  props: {
    countryName: {
      type: String,
      required: true,
    },
    countryCode: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.flag {
  display: inline-flex;
  height: 1em;
  width: 1.333em;
  border-radius: wds-rem(3px);
  background-size: cover;
  background-position: center;
}
</style>
