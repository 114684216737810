<template>
  <div class="thumbnail-list">
    <div
      v-for="(image, index) in gallery"
      :key="index"
      class="thumb-image"
      @click="$emit('show-ms-gallery', index)"
      @keypress.enter="$emit('show-ms-gallery', index)"
      role="button"
      tabindex="0"
    >
      <v-lazy-image
        :src-placeholder="require('~/assets/images/image-placeholder.svg')"
        class="thumb-image"
        :src="image.thumb || image.mobile"
        :width="width"
        :height="height"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThumbnailList',
  props: {
    gallery: {
      type: Array,
      required: false,
      default: () => [],
    },
    width: {
      type: Number,
      required: false,
      default: null,
    },
    height: {
      type: Number,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.thumbnail-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @include tablet {
    grid-template-columns: repeat(9, 1fr);
  }
  @include desktop {
    grid-template-columns: repeat(14, 1fr);
  }

  .thumb-image {
    padding: $wds-spacing-xxs;
    width: 100%;
    height: wds-rem(54px);
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      border-radius: wds-rem(4px);
      object-fit: cover;
      object-position: center;
    }
  }
}
</style>
