<template>
  <div :class="['badge', color, size]">
    <svg-icon
      class="icon"
      :name="icon"
      :aria-hidden="ariaLabel === null"
      :aria-label="ariaLabel"
      :aria-labelledby="`icon-${icon}-aria-label`"
      :aria-describedby="`icon-${icon}-aria-description`"
    />
    <label v-if="ariaLabel" class="icon-label">{{ ariaLabel }}</label>
    <label v-if="ariaDescription" class="icon-label">{{ ariaDescription }}</label>
  </div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    /** color to be in use by the badge */
    color: {
      type: String,
      required: true,
      validator: (color) => ['gold', 'green', 'blue', 'teal'].indexOf(color) !== -1,
    },
    /** icon to be inserted in the badge */
    icon: {
      type: String,
      required: true,
    },
    /** the size to display the icon in */
    size: {
      type: String,
      required: true,
      validator: (val) => ['medium', 'small'].indexOf(val) !== -1,
    },
    /**
     * aria-label
     */
    ariaLabel: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * aria-description
     */
    ariaDescription: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
// Generic styling
.badge {
  background: $wds-color-ink;
  color: $wds-color-white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: $wds-spacing-s;

  .icon {
    fill: $wds-color-white;
  }

  &.small {
    width: 2rem;
    height: 2rem;

    .icon {
      width: 1rem;
      height: 1rem;
    }
  }

  &.medium {
    width: wds-rem(45px);
    height: wds-rem(45px);

    .icon {
      width: $wds-spacing-l;
      height: $wds-spacing-l;
    }
  }
}

// Badge colors
.green {
  background: $wds-color-green;
}

.blue {
  background: $wds-color-blue;
}

.gold {
  background: $wds-color-yellow;
}

.teal {
  background: $wds-color-teal;
}

.icon-label {
  display: none;
}
</style>
