<template>
  <div class="property-headline-container">
    <div v-if="hasBadges" class="property-headline-badges">
      <tooltip v-if="hasSustainabilityBadge" position="top-start">
        <div slot="content">{{ getSustainabilityTitle }}</div>
        <wds-pill-select
          slot="tooltip-toggle"
          class="sustainability-pin"
          :title="getSustainabilityTitle"
          :aria-label="getSustainabilityTitle"
          :icon-start="getSustainabilityIcon"
          @click.stop="handleSustainabilityBadgeClick"
        />
      </tooltip>

      <tooltip v-if="badges.sanitization" position="top-start">
        <div slot="content">{{ $t('t_HEALTHSANITATIONTOOLTIP') }}</div>
        <rwd-badge slot="tooltip-toggle" color="teal" icon="sanitation" :size="getBadgeSize" aria-label="Sanitation" />
      </tooltip>

      <tooltip v-if="badges.recommended" position="top-start">
        <div slot="content">{{ $t('t_HWRECOMMENDEXPLAIN') }}</div>
        <rwd-badge
          slot="tooltip-toggle"
          color="green"
          icon="thumbs-up"
          :size="getBadgeSize"
          aria-label="Hostelworld Recommends"
        />
      </tooltip>

      <tooltip v-if="badges.awards" position="top-start">
        <div slot="content" v-html="badges.awards" />
        <rwd-badge slot="tooltip-toggle" color="gold" icon="trophy" :size="getBadgeSize" aria-label="Award" />
      </tooltip>

      <tooltip v-if="badges.freewifi" position="top-start">
        <div slot="content">{{ $t('t_FREEWIFI') }}</div>
        <rwd-badge slot="tooltip-toggle" color="blue" icon="wifi" :size="getBadgeSize" aria-label="Free Wifi" />
      </tooltip>

      <tooltip v-if="badges.breakfastincluded" position="top-start">
        <div slot="content">{{ $t('t_FREEBREAKFAST') }}</div>
        <rwd-badge
          slot="tooltip-toggle"
          color="blue"
          icon="breakfast"
          :size="getBadgeSize"
          aria-label="Breakfast Included"
        />
      </tooltip>
    </div>
    <bread-crumbs :crumbs="crumbs" />
    <h1>{{ propertyInfo.name }}</h1>
    <div class="property-headline-address-and-gallery">
      <rwd-header-property-address
        :property-id="propertyInfo.id"
        :property-type="propertyInfo.propertyTypeSlug"
        :property-friendly-name="propertyInfo.urlFriendlyName"
        :property-address-info="getPropertyAddressInfo"
      />
      <rwd-core-button v-if="hasPropertyImages" class="image-gallery-button">
        <svg-icon name="images" />
        <span>1/{{ hasPropertyImages }}</span>
      </rwd-core-button>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from '~/components/common/BreadCrumbs';
import RwdBadge from '~/components/hwrwd/common/Badge';
import RwdHeaderPropertyAddress from '~/components/hwrwd/property/HeaderPropertyAddress';
import RwdCoreButton from '~/components/hwrwd/core/Button';
import Tooltip from '~/components/hwrwd/core/Tooltip';
import { WdsPillSelect } from '@wds/components';
import mq from '~/mixins/mq';

export default {
  name: 'Headline',
  mixins: [mq],
  components: {
    RwdBadge,
    RwdHeaderPropertyAddress,
    RwdCoreButton,
    Tooltip,
    BreadCrumbs,
    WdsPillSelect,
  },
  props: {
    propertyInfo: {
      type: Object,
      required: true,
    },
    crumbs: {
      type: Array,
      required: false,
      default: () => [],
    },
    badges: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    getBadgeSize() {
      return this.isBelowMediumScreen ? 'small' : 'medium';
    },
    hasBadges() {
      return (
        this.badges.sanitization ||
        this.badges.recommended ||
        this.badges.freewifi ||
        this.badges.awards ||
        this.badges.breakfastincluded ||
        this.badges.sustainability?.tier
      );
    },
    hasPropertyImages() {
      return this.propertyInfo?.imageGallery?.length || 0;
    },
    getPropertyAddressInfo() {
      return {
        street: this.propertyInfo.address,
        city: {
          name: this.propertyInfo.city,
          urlFriendlyName: this.propertyInfo.urlFriendlyCity,
        },
        country: {
          name: this.propertyInfo.country,
          urlFriendlyName: this.propertyInfo.urlFriendlyCountry,
        },
        continent: {
          urlFriendlyName: this.propertyInfo.urlFriendlyContinent,
        },
      };
    },
    hasSustainabilityBadge() {
      return this.badges?.sustainability?.tier;
    },
    getSustainabilityIcon() {
      const sustainabilityTierIcon =
        this.badges?.sustainability?.tier === '4' ? '3-plus' : this.badges?.sustainability?.tier;
      return {
        asset: `sustainability-${sustainabilityTierIcon}`,
        color: 'green',
        width: this.isBelowMediumScreen ? 16 : 24,
        height: this.isBelowMediumScreen ? 16 : 24,
      };
    },
    getSustainabilityTitle() {
      return this.badges.sustainability?.title;
    },
  },
  methods: {
    handleSustainabilityBadgeClick() {
      this.$emit('sustainability-badge-clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.property-headline-container {
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-s;

  h1 {
    color: $wds-color-white;
    @include title-5-bld;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
    -webkit-font-smoothing: antialiased;
  }

  .property-headline-badges {
    display: flex;
    gap: $wds-spacing-m;

    ::v-deep .badge {
      margin-left: 0;
    }

    .sustainability-pin {
      padding: $wds-spacing-s;
    }
  }

  .property-headline-address-and-gallery {
    display: flex;
    gap: $wds-spacing-s;
  }

  @include tablet {
    h1 {
      @include title-2-bld;
    }

    .property-headline-address-and-gallery .image-gallery-button {
      display: none;
    }
  }

  @include tablet-large {
    .property-headline-badges .sustainability-pin {
      height: wds-rem(45px);
      width: wds-rem(45px);
      border-radius: $wds-border-radius-xxl;
    }
  }
}
</style>
