export default {
  microsite: {
    imageWidths: [
      {
        minScreenWidth: 0,
        imgWidth: 641,
        imgHeight: 400,
      },
      {
        minScreenWidth: 641,
        imgWidth: 1025,
        imgHeight: 444,
      },
      {
        minScreenWidth: 1025,
        imgWidth: 1900,
        imgHeight: 823,
      },
    ],
  },
  propertyList: {
    thumb: {
      width: 90,
      height: 46,
    },
    // @TODO Add proper mobile/tablet image dimensions when needed
    mobile: {
      width: 320,
      height: 240,
    },
    tablet: {
      width: 320,
      height: 240,
    },
    desktop: {
      width: 320,
      height: 240,
    },
    ultra: {
      width: 1920,
      height: 1080,
    },
  },
};
