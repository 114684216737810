import { PROPERTY_TYPE_DEFAULT, PROPERTY_TYPES } from '~/config/property-types';

export const findPropertyTypeByKey = (propertyType) => {
  return PROPERTY_TYPES.find((pType) => pType.key === propertyType?.toLowerCase()) || PROPERTY_TYPE_DEFAULT;
};

export const getCheapestPropertyInLocation = (properties, totalNumberOfProperties, app) => {
  return !totalNumberOfProperties
    ? null
    : properties.reduce((prevValue, property) => {
        const propertyCheapestValue = Math.min.apply(
          null,
          [property.sharedMinPrice.value, property.privateMinPrice.value].filter(Boolean)
        );

        const actualPropertyTypeObj = findPropertyTypeByKey(property?.type || propertyTypeObj.key);

        const propertyObject = {
          id: property.id,
          name: property.name,
          link: app.$url.getPropertyUrl({ propertyTypeSlug: app.i18n.t(actualPropertyTypeObj.slug) }, property),
          price: propertyCheapestValue,
        };

        prevValue = !prevValue ? propertyObject : propertyObject.price < prevValue.price ? propertyObject : prevValue;
        return prevValue;
      }, null);
};

export const parseAllProperties = (properties, totalNumberOfProperties, app) => {
  const allPropertiesObj = {
    propertiesIds: 0,
    cheapestPropertyInLocation: null,
    top30PropertiesIds: 0,
    gtmFABPropertyCards: [],
    gtmFABPropertyCardsFrom6To10: '',
    firstPropertyListing: '',
    secondPropertyListing: '',
  };

  if (!totalNumberOfProperties) {
    return allPropertiesObj;
  }

  const propertiesIdsArr = [];

  if (properties) {
    properties?.map((property, index) => {
      // Add all properties ID's for tracking
      propertiesIdsArr.push(property.id);

      // Find the cheapest property
      let prevValue = allPropertiesObj.cheapestPropertyInLocation;

      const propertyCheapestValue = Math.min.apply(
        null,
        [property.sharedMinPrice.value, property.privateMinPrice.value].filter(Boolean)
      );

      const actualPropertyTypeObj = findPropertyTypeByKey(property?.type);
      const propertyObject = {
        id: property.id,
        name: property.name,
        link: app.$url.getPropertyUrl({ propertyTypeSlug: app.i18n.t(actualPropertyTypeObj.slug) }, property),
        price: propertyCheapestValue,
        avgRating: property?.avgRating,
        numberReviews: property?.numberReviews,
      };

      allPropertiesObj.cheapestPropertyInLocation =
        prevValue?.price && prevValue.price < propertyObject?.price ? prevValue : propertyObject;

      // Add the first property from the listing for SEO
      if (index === 0) {
        allPropertiesObj.firstPropertyListing = propertyObject;
      }

      // Add the second property from the listing for SEO
      if (index === 1) {
        allPropertiesObj.secondPropertyListing = propertyObject;
      }

      // Add the top 5 properties to object for tracking
      if (index < 5) {
        const minPrice = property?.sharedMinPrice?.value || property?.privateMinPrice?.value;
        allPropertiesObj.gtmFABPropertyCards.push(`${index + 1}|${property.id}|${property?.avgRating}|${minPrice}`);
      }
    });
  }

  allPropertiesObj.propertiesIds = propertiesIdsArr?.flat()?.join('|') || 0;
  // Add all top 30 properties to string for tracking
  allPropertiesObj.top30PropertiesIds = propertiesIdsArr.slice(0, 30).flat().join('|') || 0;
  // Add the top 6 to 10 properties to object for tracking
  allPropertiesObj.gtmFABPropertyCardsFrom6To10 = propertiesIdsArr.slice(5, 10).flat().join('|') || '';

  return allPropertiesObj;
};
