<template>
  <SelectList
    :options="options"
    :selected="selected"
    searchable
    :search-placeholder="$t('t_RWDSELECTSEARCH')"
    @select="handleSelect"
  >
    <template v-slot="slotProps">
      <slot :open="slotProps.open" />
    </template>
  </SelectList>
</template>

<script>
import SelectList from '~/components/hwrwd/common/Form/SelectList/SelectList.vue';
import { setHref } from '~/lib/dom';
import { LANGUAGES } from '~/config/languages';

export default {
  name: 'LanguagePicker',

  components: {
    SelectList,
  },

  computed: {
    selected() {
      const lang = this.$store.state.session.language;
      return this.options.find((opt) => opt.language.tag === lang.tag);
    },
    options() {
      return LANGUAGES.map((lang) => ({ label: lang.name, language: lang }));
    },
  },

  methods: {
    handleSelect({ language }) {
      let url = this.$store.getters['route/getRouteFromLang'](language.tag);
      if (!url) {
        const baseURL = this.$env[language.baseUrl];
        url = this.$getLastAttributionToQuery(baseURL).href;
      }
      setHref(url);
    },
  },
};
</script>
