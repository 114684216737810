<template>
  <div class="search-container" ref="searchContainer" :class="{ sticky: isSticky && !isBelowMediumScreen }">
    <div class="rwd-wrapper">
      <search-form :location="location" />
      <button
        v-if="this.showCloseButton"
        class="close"
        :aria-label="$t('t_A11YSHEETCLOSE')"
        @keypress.enter="close"
        @click="close"
      >
        <svg-icon name="close" class="close-icon" />
        <span>{{ $t('t_A11YSHEETCLOSE') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import SearchForm from '~/components/hwrwd/common/SearchForm/index';
import mq from '~/mixins/mq';

export default {
  name: 'SearchContainer',

  components: {
    SearchForm,
  },

  mixins: [mq],

  props: {
    sticky: {
      type: Boolean,
      required: false,
      default: false,
    },
    location: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data() {
    return {
      isSticky: this.sticky,
      showCloseButton: false,
      observer: null,
    };
  },

  mounted() {
    const observer = new IntersectionObserver(
      ([event]) => {
        if (event.intersectionRatio < 1 && this.isSticky && !this.isBelowMediumScreen) {
          this.showCloseButton = true;
        } else {
          this.showCloseButton = false;
        }
      },
      { threshold: [1] }
    );

    observer.observe(this.$refs.searchContainer);

    this.observer = observer;
  },

  beforeDestroy() {
    this.observer.disconnect();
  },

  methods: {
    close() {
      this.isSticky = false;
      this.showCloseButton = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  background: #313131;
  color: #ccc;
}

.close {
  display: none;
}

@media screen and (min-width: $screen-medium-large) {
  .sticky {
    position: sticky;
    top: -1px;
    z-index: 11;
  }

  .close {
    display: flex;
    align-items: center;
    color: #ff7547;
    background: transparent;
    border: 0;
    padding: 0 0 $wds-spacing-s $wds-spacing-m;
    cursor: pointer;

    @include body-3-reg;

    .close-icon {
      margin-right: $wds-spacing-xs;
      width: wds-rem(10px);
      height: wds-rem(10px);
      fill: #ff7547;
    }
  }
}
</style>
