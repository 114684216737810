<template>
  <ul class="rating-breakdown" :class="{ 'rating-grid': applyGrid }">
    <template v-for="(score, ratingItem) in breakdown">
      <li v-if="score >= 0" :key="ratingItem" class="rating-item">
        <div class="rating-label-score-wrapper">
          <div class="rating-label">
            {{ $t('t_' + ('RATING' + ratingItem).toUpperCase()) }}
          </div>
          <div class="rating-score">
            {{ score }}
          </div>
        </div>
        <div class="rating-bar">
          <span :style="{ width: `${score * 10}%` }" />
        </div>
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  name: 'RatingBreakdown',
  props: {
    breakdown: {
      type: Object,
      required: true,
    },
    applyGrid: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-breakdown {
  list-style: none;
  margin: 0;
  padding: 0;
  @include body-3-reg;

  &.rating-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 2rem;
    @include tablet {
      grid-template-columns: repeat(4, 1fr);
    }

    .rating-bar {
      height: 0.375rem;
    }
  }

  .rating-item {
    margin: 0 0 $wds-spacing-s 0;
    overflow: hidden;
    display: inline-block;
    width: 100%;

    .rating-label-score-wrapper {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;

      .rating-label,
      .rating-score {
        color: $wds-color-ink-dark;
        margin-bottom: $wds-spacing-xs;
        text-transform: capitalize;
      }

      .rating-label {
        flex: 1 1 auto;
        @include truncate-text;
      }

      .rating-score {
        font-weight: bold;
        flex: 0 0 auto;
      }
    }

    .rating-bar {
      width: 100%;
      height: $wds-spacing-xs;
      border-radius: $wds-border_radius-s;
      background-color: $wds-color-ink-lightest;

      span {
        display: block;
        background-color: $wds-color-orange;
        height: 100%;
        border-radius: $wds-border_radius-s;
      }
    }
  }
}
</style>
