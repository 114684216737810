<template>
  <nav class="rwd-wrapper">
    <div class="hw-brand">
      <a :href="getHwUrl" :aria-label="commonValues.HOSTELWORLD" rel="noopener" data-testid="hw-brand-link">
        <img
          src="https://i.hwstatic.com/static/images/4.130.0.0/hw_logo.svg"
          :title="commonValues.HOSTELWORLD"
          :alt="commonValues.HOSTELWORLD"
          width="200"
          height="43"
        />
      </a>
    </div>

    <div class="top-navbar">
      <button
        class="nav-button login-button"
        :title="$t('t_A11YLOGINTOHWACCOUNT')"
        :aria-label="$t('t_A11YLOGINTOHWACCOUNT')"
        @keypress.enter="handleLoginClick"
        @click="handleLoginClick"
      >
        <svg-icon name="user-account" class="svg-icon" />
      </button>

      <LanguagePicker
        id="header-language-picker"
        class="language-picker item"
        role="listbox"
        :title="getCurrentLanguage.name"
        :aria-label="getCurrentLanguage.name"
      >
        <template v-slot="slotProps">
          <button
            :class="['nav-button language-button icon-menu-item', { 'open-menu-item': slotProps.open }]"
            :title="getCurrentLanguage.name"
            :aria-label="getCurrentLanguage.name"
          >
            {{ getCurrentLanguage.name }}
          </button>
        </template>
      </LanguagePicker>

      <CurrencyPicker
        id="header-currency-picker"
        class="currency-picker item"
        role="listbox"
        :title="getCurrentCurrency.code"
        :aria-label="getCurrentCurrency.code"
      >
        <template v-slot="slotProps">
          <button
            :class="['nav-button currency-button icon-menu-item', { 'open-menu-item': slotProps.open }]"
            :title="getCurrentCurrency.code"
            :aria-label="getCurrentCurrency.code"
          >
            {{ getCurrentCurrency.code }}
          </button>
        </template>
      </CurrencyPicker>

      <button
        class="nav-button hamburger-button"
        :title="$t('t_A11YBURGERMENU')"
        :aria-label="$t('t_A11YBURGERMENU')"
        @click="openHamburguerMenu"
        @keypress.enter="openHamburguerMenu"
      >
        <svg-icon name="burger" class="svg-icon" />
      </button>

      <HamburgerMenu v-if="isHamMenuOpen" @close="isHamMenuOpen = false" />
    </div>
  </nav>
</template>

<script>
import LanguagePicker from '~/components/hwrwd/common/LanguagePicker/index';
import CurrencyPicker from '~/components/hwrwd/common/CurrencyPicker/index';
import { mapActions, mapGetters } from 'vuex';
import { commonValues } from '~/config/common';
import { setHref } from '~/lib/dom';

export default {
  name: 'HeaderNav',

  components: {
    LanguagePicker,
    CurrencyPicker,
    HamburgerMenu: /* istanbul ignore next */ () =>
      import(/* webpackMode: "lazy" */ '~/components/hwrwd/common/HamburgerMenu/index'),
  },

  data() {
    return {
      tracking: null,
      isHamMenuOpen: false,
      commonValues: commonValues,
    };
  },

  computed: {
    ...mapGetters({
      getCurrentLanguage: 'session/getCurrentLanguage',
      getCurrentCurrency: 'session/getCurrentCurrency',
    }),

    getHwUrl() {
      return `${this.$url.getHomepageUrl()}`;
    },

    getLoginUrl() {
      return `${this.$url.getBaseURL()}${commonValues.HOSTELWORLD_LOGIN_URL}`;
    },
  },

  mounted() {
    this.getTracking();
  },

  methods: {
    ...mapActions({
      toggleLanguagePicker: 'session/toggleLanguagePicker',
      toggleCurrencyPicker: 'session/toggleCurrencyPicker',
    }),

    async getTracking() {
      this.tracking = await this.$tracking.NavBarComponent();
    },

    handleLoginClick() {
      this.tracking?.onLoginClick();
      setTimeout(() => {
        setHref(this.getLoginUrl);
      }, 100);
    },

    openHamburguerMenu() {
      if (!this.isHamMenuOpen) {
        this.isHamMenuOpen = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: $wds-spacing-m wds-rem(12px);
  width: 100%;

  .hw-brand {
    display: flex;

    img {
      width: 200px;
      height: 43px;
    }

    a {
      display: inline-flex;
      text-decoration: none;
      outline: none;

      &:focus {
        box-shadow: 0 0 0 $wds-spacing-xxs $wds-color-white, 0 0 0 $wds-spacing-xs $wds-color-ink-darker;
        outline: none;
      }
    }
  }

  @media screen and (min-width: $screen-medium-large) {
    .hw-brand {
      img {
        width: 300px;
        height: 64px;
      }
    }
  }

  .top-navbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .nav-button {
      min-width: $wds-spacing-xl;
      min-height: $wds-spacing-xl;
      border: 0.0625rem solid transparent;
      background-color: rgba(0, 0, 0, 0.3);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: $wds-spacing-m;
      font-size: wds-rem(13px);
      color: $wds-color-white;
      border-radius: wds-rem(5px);
      font-weight: 700;

      &:focus {
        box-shadow: 0 0 0 $wds-spacing-xxs $wds-color-white, 0 0 0 $wds-spacing-xs $wds-color-ink-darker;
      }

      .svg-icon {
        width: wds-rem(20px);
        height: wds-rem(20px);
        fill: $wds-color-white;
      }

      &.login-button,
      &.language-button,
      &.currency-button {
        display: none;
      }

      @media screen and (min-width: $screen-medium-large) {
        &.login-button,
        &.language-button,
        &.currency-button {
          display: flex;
        }
      }
    }
  }

  .language-picker,
  .currency-picker {
    display: none;

    @media screen and (min-width: $screen-medium-large) {
      display: block;
    }
  }

  #header-language-picker,
  #header-currency-picker {
    ::v-deep .menu {
      display: none;

      @media screen and (min-width: $screen-medium-large) {
        display: block;
      }
    }
  }

  #header-language-picker {
    ::v-deep .menu {
      @media screen and (min-width: $screen-medium-large) {
        left: wds-rem(-14px);
      }
    }
  }

  #header-currency-picker {
    ::v-deep .menu {
      @media screen and (min-width: $screen-medium-large) {
        left: wds-rem(-52px);
      }
    }
  }
}
</style>
