<template>
  <section name="awards">
    <!-- Awards -->
    <div v-if="awards.length" class="awards">
      <div class="section">
        <div v-for="(award, i) in awards" :key="i" class="entry">
          <badge class="badge" color="gold" icon="trophy" size="small" aria-label="A Gold Trophy" />
          <div class="info">
            <h2 class="year">{{ award.year }}</h2>
            <p class="description">
              {{ getAwardDescription(award) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Nominations -->
    <div v-if="nominations.length" class="nominations">
      <p class="nominations-title">{{ $t('t_NOMINATIONS') }}</p>
      <div class="section">
        <div v-for="(nomination, i) in nominations" :key="i" class="entry">
          <badge class="badge" color="gold" icon="bookmark" size="small" />
          <div class="info">
            <h2 class="year">{{ nomination.year }}</h2>
            <p class="description">
              {{ getNominationDescription(nomination) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Show all winners -->
    <a v-if="displayViewAll" class="view-all" :href="hoscarsUrl">View all Award Winners</a>
  </section>
</template>

<script>
import Badge from '~/components/hwrwd/common/Badge';

export default {
  name: 'Awards',
  components: {
    Badge,
  },
  props: {
    /** awards */
    awards: {
      type: Array,
      required: true,
    },
    /** nominations */
    nominations: {
      type: Array,
      required: true,
    },
  },
  computed: {
    /** This is only displayed for en users */
    displayViewAll() {
      return this.$store.state.session.language.lang === 'en';
    },
    hoscarsUrl() {
      return `${this.$env.HOSTELWORLD_URL}/hoscars`;
    },
  },
  methods: {
    /**
     * Formats and add translations for award description
     * @param {Object} award
     * @returns {string}
     */
    getAwardDescription(award) {
      const place = this.$t(`t_HOSCARSPLACE${award.place}`);
      let { key, other } = award;
      key = this.$t('t_' + key);

      return `${place}, ${key} ${other}`.trim();
    },
    /**
     * Formats and add translations for nomination description
     * @param {Object} nomination
     * @returns {string}
     */
    getNominationDescription(nomination) {
      return this.$t('t_' + nomination.key);
    },
  },
};
</script>

<style lang="scss" scoped>
.nominations-title {
  color: $wds-color-ink-dark;
  padding: $wds-spacing-m 0;
  font-weight: 300;
}

.section {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr;

  .entry {
    padding-bottom: $wds-spacing-m;
    padding-right: $wds-spacing-m;
    display: grid;
    grid-template-areas: 'badge info';
    grid-template-columns: $wds-spacing-xl auto;

    .info {
      grid-area: info;
      padding-left: $wds-spacing-m;
      @include body-3-reg;

      .year {
        font-weight: bold;
      }
    }
  }
  @include tablet {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include desktop {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.view-all {
  @include body-3-bld;
}
</style>
