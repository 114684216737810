<template>
  <SelectList
    :options="options"
    :selected="selected"
    searchable
    :search-placeholder="$t('t_RWDSELECTSEARCH')"
    @select="handleSelect"
  >
    <template v-slot="slotProps">
      <slot :open="slotProps.open" />
    </template>
  </SelectList>
</template>

<script>
import SelectList from '~/components/hwrwd/common/Form/SelectList/SelectList.vue';
import { CURRENCIES, DEFAULT_CURRENCY } from '~/config/currencies';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CurrencyPicker',

  components: {
    SelectList,
  },

  data() {
    return {
      currencies: CURRENCIES,
    };
  },

  computed: {
    ...mapGetters({
      getCurrentCurrency: 'session/getCurrentCurrency',
    }),
    selected() {
      const currency = this.$store.state.session.currency;
      return this.options.find((opt) => opt.currency.code === currency.code);
    },
    options() {
      return this.currencies.map((c) => {
        const name = this.$t(c.transcode);
        return { label: `${name} (${c.code})`, currency: c };
      });
    },
  },

  methods: {
    ...mapActions({
      setCurrency: 'session/setCurrency',
    }),

    async handleSelect({ currency }) {
      const selectedCurrency = currency.code || DEFAULT_CURRENCY.code;
      this.setCurrency(selectedCurrency);
    },
  },
};
</script>
