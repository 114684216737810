<template>
  <div class="property-info-bar-container">
    <ul class="rwd-wrapper property-info-bar-list">
      <li v-for="infoBarItem in infoBarItems" :key="infoBarItem.code" :class="infoBarItem.classes">
        <button
          type="button"
          @click="emitItemClick(infoBarItem.code)"
          @keypress.enter="emitItemClick(infoBarItem.code)"
        >
          {{ infoBarItem.text }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'InfoBar',

  data() {
    return {
      tracking: null,
    };
  },

  mounted() {
    this.getTracking();
  },

  computed: {
    infoBarItems() {
      return [
        {
          code: 'prices',
          text: this.$t('t_RWDINFOBARPRICES'),
        },
        {
          code: 'facilities',
          text: this.$t('t_RWDINFOBARFACILITIES'),
        },
        {
          code: 'map',
          text: this.$t('t_RWDINFOBARMAP'),
        },
        {
          code: 'reviews',
          text: this.$t('t_RWDINFOBARREVIEWS'),
        },
        {
          code: 'houserules',
          text: this.$t('t_RWDINFOBARHOUSERULES'),
        },
      ];
    },
  },

  methods: {
    ...mapGetters({
      getPropertyTypeKey: 'tracking/getPropertyTypeKey',
      getTrackingDetails: 'tracking/getTrackingDetails',
      getPageType: 'tracking/getPageType',
      getProductId: 'tracking/getProductId',
      getPropertyName: 'tracking/getPropertyName',
      getPropertyDepositPercent: 'tracking/getPropertyDepositPercent',
      getPropertyClass: 'tracking/getPropertyClass',
      getPropertyCurrency: 'tracking/getPropertyCurrency',
    }),

    async getTracking() {
      this.tracking = await this.$tracking.PropertyPage();
    },

    emitItemClick(item) {
      const trackingDetails = this.getTrackingDetails();

      const trackingInfo = {
        segment: {
          page_type: this.getPageType(),
          destination_city: trackingDetails?.city,
          destination_country: trackingDetails?.country,
          destination_continent: trackingDetails?.continent,
          destination_district: trackingDetails?.district,
          product_id: this.getProductId()?.toString(),
          sku: this.getProductId()?.toString(),
          property_type: this.getPropertyTypeKey(),
          property_name: this.getPropertyName(),
          property_deposit_percent: this.getPropertyDepositPercent(),
          property_class: this.getPropertyClass(),
          property_currency: this.getPropertyCurrency(),
        },
      };

      this.tracking?.onInfoBarClick(item, trackingInfo.segment);
      this.$emit('item-click', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.property-info-bar-container {
  background-color: $wds-color-ink-lightest;
  box-shadow: inset 0 0 0 wds-rem(1px) $wds-color-ink-lighter;
  width: 100%;
  overflow-x: auto;

  .property-info-bar-list {
    padding: $wds-spacing-xs 0;
    display: flex;
    align-items: center;
    height: wds-rem(56px);

    li {
      display: flex;
      align-items: center;
      height: $wds-spacing-l;
      border-right: 1px solid $wds-color-ink-lighter;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        border: none;
      }

      button {
        border: none;
        background: none;
        color: $wds-color-ink-dark;
        cursor: pointer;
        text-transform: capitalize;
        font-size: $wds-font-size-body-1;
        padding: 0 $wds-spacing-m;
        height: wds-rem(56px);
        white-space: nowrap;
      }
    }
  }

  @include tablet {
    .property-info-bar-list {
      padding: 0 $wds-spacing-m;
      justify-content: flex-start;
    }
  }
}
</style>
