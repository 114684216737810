<template functional>
  <div
    v-if="props.images"
    class="rwd-hero-image"
    :style="`--bg-hero-image-mobile: url(${props.images.mobile}); --bg-hero-image-tablet: url(${props.images.tablet}); --bg-hero-image-desktop: url(${props.images.desktop});`"
  >
    <div class="hero-image-overlay"></div>
    <div class="hero-image-gradient"></div>
    <div class="hero-image-footer"></div>
  </div>
</template>

<script>
export default {
  name: 'HeroImage',

  props: {
    images: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.rwd-hero-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  min-height: 240px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #333;
  background-image: var(--bg-hero-image-mobile);
  transition: background-image 0.5s ease-in-out;

  @media screen and (min-width: $screen-medium-large) {
    background-image: var(--bg-hero-image-tablet);
    min-height: 430px;
  }

  @media screen and (min-width: $screen-large) {
    background-image: var(--bg-hero-image-desktop);
    min-height: 430px;
  }

  .hero-image-overlay {
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
    min-height: 240px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    outline: 0;

    @media screen and (min-width: $screen-medium-large) {
      min-height: 430px;
    }
  }

  .hero-image-gradient {
    pointer-events: none;
    width: 100%;
    height: 180px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-image: url('https://i.hwstatic.com/static/images/4.130.0.0/home_gradient.png');
    background-repeat: repeat-x;
    background-position: left -100px;
    outline: 0;
  }

  .hero-image-footer {
    pointer-events: none;
    padding-top: 3rem;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.7) 100%);

    @media screen and (min-width: $screen-medium-large) {
      padding-top: 10rem;
    }
  }
}
</style>
