<template functional>
  <section class="recommended-container">
    <div class="badge">
      <svg-icon
        class="icon"
        name="thumb"
        :aria-label="`${parent.$t('t_HWLOVESTHISHOSTEL')}`"
        :title="`${parent.$t('t_HWLOVESTHISHOSTEL')}`"
        :alt="`${parent.$t('t_HWLOVESTHISHOSTEL')}`"
      />
      <span>{{ parent.$t('t_HWLOVESTHISHOSTEL') }}</span>
    </div>
    <p>{{ parent.$t('t_HWLOVESTHISHOSTELDESC') }}</p>
  </section>
</template>

<script>
export default {
  name: 'RecommendedProperty',
};
</script>

<style lang="scss" scoped>
.recommended-container {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  padding-bottom: $wds-spacing-m;
  border-bottom: 1px dotted $wds-color-ink-lighter;

  @media only screen and (min-width: $screen-large) {
    display: none;
  }

  .badge {
    // @TODO: This color doesn't exist on WDS
    background: #00c08a;
    margin-right: $wds-spacing-m;
    color: $wds-color-white;
    display: inline-flex;
    border-radius: wds-rem(6px);
    padding: wds-rem(6px) $wds-spacing-s;
    align-items: center;
    font-size: $wds-font-size-body-2;
    font-weight: $wds-font-weight-bold;

    svg {
      width: $wds-spacing-m;
      height: $wds-spacing-m;
      fill: $wds-color-white;
      margin-right: $wds-spacing-s;
    }
  }

  p {
    // @TODO: This color doesn't exist on WDS
    color: #00c08a;
    margin: $wds-spacing-s 0 0;
    font-size: $wds-font-size-body-2;

    @media only screen and (min-width: $screen-medium) {
      margin: $wds-spacing-s 0;
    }
  }
}
</style>
