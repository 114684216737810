<template>
  <div ref="calendar" class="calendar-month">
    <div class="month-name">{{ monthName }} {{ year }}</div>

    <div v-for="week in weekNames" :key="week" class="week-name">{{ week.substring(0, 2) }}</div>

    <div
      v-for="day in totalDays"
      :key="day"
      :ref="`calendar-day-${day}`"
      class="day"
      :tabindex="day && isToday(day) ? 0 : -1"
      :class="{
        today: day && isToday(day),
        disabled: day && disabledDay(day),
        start: day && selectedStartDay(day),
        end: day && selectedEndDay(day),
        range: day && dayInRange(day),
        empty: !day,
      }"
      @click="dayClicked(day)"
      @keydown.left="moveLeft(day)"
      @keydown.right="moveRight(day)"
      @keydown.enter.space.prevent="dayClicked(day)"
    >
      <div>{{ day }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Calendar',
  props: {
    month: {
      type: Number,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    selected: {
      type: Object,
      required: false,
      default: () => {
        return {
          start: { year: 0, month: 0, day: 0 },
          end: { year: 0, month: 0, day: 0 },
        };
      },
    },
    disabledUntil: {
      type: Object,
      required: false,
      default: () => {
        return { year: 0, month: 0, day: 0 };
      },
    },
    disabledFrom: {
      type: Object,
      required: false,
      default: () => {
        return { year: 0, month: 0, day: 0 };
      },
    },
  },
  data() {
    return {
      totalDays: this.days(),
      selectedIndex: 0,
      currentMonth: this.month,
    };
  },
  computed: {
    weekNames() {
      return [
        this.$t('t_MON'),
        this.$t('t_TUE'),
        this.$t('t_WED'),
        this.$t('t_THU'),
        this.$t('t_FRI'),
        this.$t('t_SAT'),
        this.$t('t_SUN'),
      ];
    },
    monthNames() {
      return [
        this.$t('t_JANUARY'),
        this.$t('t_FEBRUARY'),
        this.$t('t_MARCH'),
        this.$t('t_APRIL'),
        this.$t('t_MAY'),
        this.$t('t_JUNE'),
        this.$t('t_JULY'),
        this.$t('t_AUGUST'),
        this.$t('t_SEPTEMBER'),
        this.$t('t_OCTOBER'),
        this.$t('t_NOVEMBER'),
        this.$t('t_DECEMBER'),
      ];
    },
    monthName() {
      return this.monthNames[this.month - 1];
    },
  },
  mounted() {
    if (this.selected.start.day) {
      this.focusOnDay(this.selected.start.day);
    }
  },
  methods: {
    days() {
      return this.skipDays().concat(this.createRange(1, this.countDays()));
    },
    createRange(firstDay, lastDay) {
      return Array.from({ length: lastDay - firstDay + 1 }, (x, dayFromZero) => dayFromZero + firstDay);
    },
    countDays() {
      return new Date(this.year, this.month, 0).getDate();
    },
    skipDays() {
      // All days start on Sunday, so we remove one to start the week on Monday
      const daysToSkip = new Date(this.year, this.month - 1, 1).getDay();
      // If the first day is Sunday we need to skip until the first day available in the calendar
      return new Array(daysToSkip === 0 ? 6 : daysToSkip - 1);
    },
    dayClicked(day) {
      if (!day || this.disabledDay(day)) {
        return;
      }

      this.$emit('daySelected', {
        year: this.year,
        month: this.month,
        day: day,
      });
    },
    getDates(day) {
      return {
        today: new Date().setHours(0, 0, 0, 0),
        currentDate: new Date(this.year, this.month - 1, day).getTime(),
        startDate: new Date(this.selected.start.year, this.selected.start.month - 1, this.selected.start.day).getTime(),
        endDate: new Date(this.selected.end.year, this.selected.end.month - 1, this.selected.end.day).getTime(),
        disabledUntilDate: new Date(
          this.disabledUntil.year,
          this.disabledUntil.month - 1,
          this.disabledUntil.day
        ).getTime(),
        disabledFromDate: this.disabledFrom.year
          ? new Date(this.disabledFrom.year, this.disabledFrom.month - 1, this.disabledFrom.day).getTime()
          : null,
      };
    },
    isToday(day) {
      const dates = this.getDates(day);
      return dates.currentDate === dates.today;
    },
    disabledDay(day) {
      const dates = this.getDates(day);

      const validateDatesUntil = dates.currentDate < dates.today || dates.currentDate < dates.disabledUntilDate;
      const validateDatesFrom = dates.disabledFromDate ? dates.currentDate > dates.disabledFromDate : false;

      return validateDatesUntil || validateDatesFrom;
    },
    selectedStartDay(day) {
      const dates = this.getDates(day);
      return dates.currentDate === dates.startDate;
    },
    selectedEndDay(day) {
      const dates = this.getDates(day);
      return dates.currentDate === dates.endDate;
    },
    dayInRange(day) {
      if (this.selected.start.year === 0 || this.selected.end.year === 0) {
        return false;
      }

      const dates = this.getDates(day);
      return dates.startDate < dates.currentDate && dates.endDate > dates.currentDate;
    },
    moveRight(day) {
      const index = day + 1;

      if (this.disabledDay(index)) {
        return;
      }

      if (index > this.totalDays[this.totalDays.length - 1]) {
        if (this.$parent && this.$parent.$refs.nextMonth) {
          this.$parent.$refs.nextMonth.click();
        }

        this.$nextTick(() => {
          this.focusOnDay(1);
        });
        return;
      }

      this.focusOnDay(index);
    },
    moveLeft(day) {
      const index = day - 1;

      if (this.disabledDay(index)) {
        return;
      }

      if (!index) {
        if (this.$parent && this.$parent.$refs.previousMonth) {
          this.$parent.$refs.previousMonth.click();
        }

        this.$nextTick(() => {
          this.focusOnDay(this.totalDays[this.totalDays.length - 1]);
        });
        return;
      }

      this.focusOnDay(index);
    },
    focusOnDay(day) {
      if (this.$refs[`calendar-day-${day}`]) {
        this.$refs[`calendar-day-${day}`][0].focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-month {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-template-areas: 'month month month month month month month';
  grid-column-gap: 0;
  grid-row-gap: 0.2rem;
  user-select: none;
  height: wds-rem(290px);

  .month-name {
    text-transform: capitalize;
    grid-area: month;
    text-align: center;
    min-height: wds-rem(40px);
    max-height: wds-rem(40px);
    align-items: center;
    display: grid;
    font-size: $wds-font-size-body-1;
    font-weight: bold;
    color: $wds-color-ink-darker;
  }

  .week-name {
    display: grid;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    min-height: wds-rem(30px);
    max-height: wds-rem(30px);
    font-size: $wds-font-size-body-2;
    color: $wds-color-ink;
  }

  .day {
    display: grid;
    justify-content: center;
    align-items: center;
    color: $wds-color-black;
    text-align: center;
    font-size: $wds-font-size-body-2;

    & > div {
      display: grid;
      align-items: center;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;

      &:hover {
        background-color: $wds-color-orange;
        color: $wds-color-white;
        cursor: pointer;
      }
    }

    &.today {
      color: $wds-color-orange;
    }

    &.start,
    &.end {
      div {
        color: $wds-color-white;
        background-color: $wds-color-orange;
      }
    }

    &.disabled {
      color: $wds-color-ink;

      div {
        cursor: default;

        &:hover {
          color: $wds-color-ink;
          background-color: $wds-color-white;
        }
      }
    }

    &.empty {
      div:hover {
        color: $wds-color-ink;
        background-color: $wds-color-white;
      }
    }
  }
}
</style>
