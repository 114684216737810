<template>
  <popper
    :visible-arrow="visible"
    :trigger="trigger"
    :force-show="forceShow"
    :options="{
      placement: position,
      modifiers: { offset: { offset: '0,10px' } },
    }"
    @show="$emit('show')"
  >
    <div class="popper">
      <slot name="content"></slot>
    </div>
    <slot slot="reference" name="tooltip-toggle"></slot>
  </popper>
</template>

<script>
import Popper from 'vue-popperjs';

export default {
  components: {
    Popper,
  },
  props: {
    position: {
      type: String,
      default: 'top',
      validator: (prop) =>
        [
          'auto',
          'top',
          'top-start',
          'top-end',
          'bottom',
          'bottom-start',
          'bottom-end',
          'left',
          'left-start',
          'left-end',
          'right',
          'right-start',
          'right-end',
        ].includes(prop),
    },
    arrow: {
      type: Boolean,
      default: false,
      required: false,
    },
    trigger: {
      type: String,
      default: 'hover',
      required: false,
    },
    visible: {
      type: Boolean,
      default: false,
      required: false,
    },
    forceShow: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>
<style lang="scss">
@import 'vue-popperjs/dist/vue-popper.css';

.popper {
  @include body-3-reg;

  max-width: wds-rem(296px);
  text-align: left;
  padding: $wds-spacing-s;
  z-index: $wds-z_index-toast;
  box-shadow: $wds-shadow-light-s;
  border-radius: $wds-spacing-xs;

  .tooltip-title {
    font-weight: 600;
  }
}
</style>
