<template>
  <div class="review-card-container">
    <div class="wrapper">
      <div class="details">
        <rating-summary :rating="rating" :show-keyword="showKeyword" :size="size" />
        <div class="date">
          <svg-icon name="calendar" class="icon-stayed-in" />
          <span class="text-stayed-in">{{ review.reviewDate }}</span>
        </div>
      </div>

      <div class="description-wrapper">
        <collapsible :show-more="$t('t_SHOWMORE')" :show-less="$t('t_SHOWLESS')" :lines="lines">
          <template slot="description">
            {{ review.reviewText }}
          </template>
        </collapsible>
      </div>
    </div>

    <template v-if="showReviewAuthor">
      <div class="balloon-arrow" />
      <div class="review-author">
        <a v-if="reviewLink" :href="getPropertyReviewUrl">
          {{ $t('t_VERIFIEDTRAVELLER', { PROPERTYNAME: review.propertyName }) }}
        </a>
        <template v-else>
          <Flag :country-code="author.countryCode" :country-name="author.countryName" class="review-author-flag" />
          <span>{{ details }}</span>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
/**
 * This component renders a review card.
 *
 * @see See [Zeplin](TBA)
 */
import RatingSummary from '~/components/hwrwd/common/Ratings/RatingSummary';
import Collapsible from '~/components/hwrwd/core/Collapsible';
import Flag from '~/components/hwrwd/common/Flag';

export default {
  name: 'ReviewCard',
  components: {
    RatingSummary,
    Collapsible,
    Flag,
  },
  props: {
    review: {
      type: Object,
      required: true,
    },
    rating: {
      type: Object,
      required: true,
    },
    lines: {
      type: Number,
      required: false,
      default: 5,
    },
    showReviewAuthor: {
      type: Boolean,
      required: false,
      default: true,
    },
    showKeyword: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'big',
      validator: (value) => {
        return ['big', 'medium', 'small'].indexOf(value) !== -1;
      },
    },
    author: {
      type: Object,
      required: false,
      default: null,
    },
    reviewLink: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    // TODO add the proper URL for properties with review modal open
    getPropertyReviewUrl() {
      return '/property';
    },
    details() {
      const details = [];
      this.author.groupTypeCode && details.push(this.$t(`t_${this.author.groupTypeCode.toUpperCase().trim()}`));
      this.author.ageRange && details.push(this.author.ageRange);
      return details.join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
.review-card-container {
  position: relative;
  min-height: wds-rem(150px);

  .wrapper {
    background-color: $wds-color-white;
    padding: $wds-spacing-m;
    border: wds-rem(1px) solid $wds-color-ink-lightest;
    border-radius: $wds-border-radius-m;
    min-height: wds-rem(170px);
  }

  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $wds-spacing-m;

    .date {
      display: flex;
      align-items: center;
      gap: $wds-spacing-s;
      font-size: $wds-font-size-body-3;
      line-height: $wds-line-height-body-3;
      color: $wds-color-ink;

      .icon-stayed-in {
        fill: $wds-color-ink;
        width: $wds-spacing-m;
        height: $wds-spacing-m;
      }

      .text-stayed-in {
        white-space: nowrap;
      }
    }
  }

  .description-wrapper {
    ::v-deep div {
      font-size: $wds-font-size-body-3;
      line-height: $wds-line-height-body-3;
      letter-spacing: $wds-letter-spacing-body-3;
      color: $wds-color-ink-dark;
      margin-bottom: 0;
      text-overflow: ellipsis;
    }

    ::v-deep .toggle-content {
      font-size: $wds-font-size-body-3;
      color: $wds-color-orange;
      text-decoration: none;
      text-transform: capitalize;
      font-weight: normal;
      display: inline-block;
      margin-top: $wds-spacing-xs;

      &:hover,
      &:active,
      &:focus {
        color: $wds-color-orange-dark;
        text-decoration: underline;
        outline: none;
      }
    }
  }

  .balloon-arrow {
    width: wds-rem(12px);
    height: wds-rem(12px);
    position: absolute;
    margin-top: wds-rem(-6px);
    left: wds-rem(12px);
    background-color: $wds-color-white;
    border-right: wds-rem(1px) solid $wds-color-ink-lightest;
    border-bottom: wds-rem(1px) solid $wds-color-ink-lightest;
    transform: rotate(45deg);
  }

  .review-author {
    display: flex;
    align-items: center;
    margin: $wds-spacing-m $wds-spacing-s;
    font-size: $wds-font-size-body-3;
    color: $wds-color-ink;
    line-height: $wds-line-height-body-3;
    letter-spacing: wds-rem(0.2px);

    a {
      color: $wds-color-orange;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        color: $wds-color-orange-dark;
        text-decoration: underline;
        outline: none;
      }
    }
  }
}

.icon-core-calendar {
  display: flex;
  align-items: center;
}

.review-author-flag {
  font-size: $wds-font-size-body-2;
  margin-right: $wds-spacing-s;
  margin-left: 1px;
}
</style>
