<template functional>
  <p class="free-cancellation">
    <span><svg-icon class="icon" name="free-cancellation" />{{ parent.$t('t_FREECANCELLATION') }}</span>
    {{ parent.$t('t_FREECANCELLATIONDIAVARIABLE', { DAYSINADVANCE: props.daysInAdvance }) }}
  </p>
</template>

<script>
export default {
  name: 'FreeCancellation',

  props: {
    daysInAdvance: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.free-cancellation {
  background: #313131;
  color: $wds-color-white;
  padding: 0 $wds-spacing-s $wds-spacing-m $wds-spacing-s;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include body-2-bld;

  span {
    border-bottom: $wds-spacing-xxs solid $wds-color-green;
    margin: $wds-spacing-s $wds-spacing-m $wds-spacing-s 0;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    @include body-1-bld;
  }

  .icon {
    width: $wds-spacing-xl;
    height: $wds-spacing-xl;
    margin: 0 $wds-spacing-s $wds-spacing-xs 0;
  }

  @media screen and (min-width: $screen-medium-large) {
    flex-direction: row;
  }
}
</style>
