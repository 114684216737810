<template>
  <wds-app-banner
    v-if="showAppDownloadBanner"
    :title="$t(appDownloadTitle)"
    :subtitle="$t(appDownloadSubTitle)"
    :action-button="$t('t_APPBANNEROPEN').toUpperCase()"
    @dismiss-click="handleAppBannerDismiss"
    @banner-click="handleAppBannerClick"
  />
</template>

<script>
import { WdsAppBanner } from '@wds/components';
import { parse, serialize } from '~/lib/cookie';
import { setCookie } from '~/lib/dom';

const APP_BANNER_COOKIE_NAME = 'appBannerDismissed';
const APP_BANNER_MAX_DISMISS = 7 * 86400;
const APP_BANNER_LINK = 'https://hostelworld.onelink.me/IdiK/vnn7oxyn';

export default {
  name: 'AppDownloadBanner',
  components: { WdsAppBanner },

  props: {
    page: {
      type: String,
      required: true,
    },
    linkPaths: {
      type: Object,
      required: true,
    },
    linkParams: {
      type: String,
      default: 'search',
    },
  },

  data() {
    return {
      tracking: null,
      appBanner: {
        enabled: false,
        show: false,
        variation: 'a',
        vars: {},
      },
    };
  },

  computed: {
    showAppDownloadBanner() {
      return this.appBanner.enabled && this.$device.isMobileOrTablet && this.appBanner.show;
    },
    appDownloadTitle() {
      return this.appBanner.variation === 'b' ? 't_APPBANNERTITLEPRICING' : 't_APPBANNERTITLESOCIAL';
    },
    appDownloadSubTitle() {
      return this.appBanner.variation === 'b' ? 't_APPBANNERSUBTITLEPRICING' : 't_APPBANNERSUBTITLESOCIAL';
    },
  },

  created() {
    const web_appDownloadBannerEnabled = this.$optimizely.isFeatureEnabled('web_app_download_banner');
    this.appBanner.vars = this.$optimizely.getAllFeatureVariables('web_app_download_banner');
    this.appBanner.enabled = web_appDownloadBannerEnabled && this.appBanner.vars?.staticpages?.[this.page];
  },

  mounted() {
    this.getTracking();

    if (this.appBanner.enabled) {
      const cookiesContent = document.cookie ? parse(document.cookie) : {};
      const appBannerDismissCookie = cookiesContent[APP_BANNER_COOKIE_NAME];
      if (appBannerDismissCookie) {
        const timestamp = Math.floor(Date.now() / 1000);
        this.appBanner.show = timestamp - appBannerDismissCookie >= APP_BANNER_MAX_DISMISS;
      } else {
        this.appBanner.show = true;
      }

      this.appBanner.variation = this.appBanner.vars?.message || 'a';
    }
  },

  methods: {
    async getTracking() {
      this.tracking = await this.$tracking.GenericPage();
    },
    handleAppBannerClick() {
      this.tracking?.onAppDownloadBannerOpen(this.page);

      const appDeepLinkPath = this.linkPaths[this.appBanner.variation] || this.linkPaths['a'] || APP_BANNER_LINK;
      window.location.href = `${appDeepLinkPath}?deep_link_sub10=${btoa(`hw://s/${this.linkParams}`)}`;
    },
    handleAppBannerDismiss() {
      const timestamp = Math.floor(Date.now() / 1000);
      setCookie(
        serialize(APP_BANNER_COOKIE_NAME, timestamp, {
          domain: this.$env.COOKIE_DOMAIN,
          path: '/',
          secure: this.$env.COOKIE_SECURE !== 'false',
          sameSite: 'Strict',
        })
      );
      this.appBanner.show = false;

      this.tracking?.onAppDownloadBannerDismiss(this.page);
    },
  },
};
</script>
