<template functional>
  <component
    :is="props.type"
    class="button"
    :class="[
      {
        primary: props.color === 'primary',
        secondary: props.color === 'secondary',
        link: props.color === 'link',
        dark: props.color === 'dark',
        small: props.size === 'small',
        large: props.size === 'large',
        'full-width': props.fullWidth,
        disabled: props.disabled,
        icon: props.icon,
      },
      data.class,
      data.staticClass,
    ]"
    :disabled="props.disabled"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'Button',

  props: {
    /**
     * The underlying HTML element type or vue component.
     */
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    /**
     * The button size.
     */
    size: {
      type: String,
      required: false,
      default: 'small',
      validator: (val) => ['small', 'large'].includes(val),
    },
    /**
     * The button color scheme.
     */
    color: {
      type: String,
      required: false,
      default: 'primary',
      validator: (val) => ['primary', 'secondary', 'confirmation', 'link', 'dark'].includes(val),
    },
    /**
     * Take up the full width of the parent container.
     */
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Prevent the button from being interacted with.
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Format the button to hold an icon.
     */
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  display: inline-block;
  min-height: 1rem;
  vertical-align: baseline;
  user-select: none;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  border-radius: wds-rem(4px);
  outline: none;
  border: none;
  line-height: normal;
  transition: background 150ms ease-in-out;

  &:not(:last-child) {
    margin-right: $wds-spacing-m;
  }

  &.small {
    font-size: $wds-font-size-body-2;
    letter-spacing: $wds-letter-spacing-body-2;
    padding: $wds-spacing-s $wds-spacing-l;
  }

  &.large {
    font-size: $wds-font-size-body-1;
    letter-spacing: $wds-letter-spacing-body-1;
    padding: $wds-spacing-m $wds-spacing-xl;
  }

  &.primary {
    font-weight: bold;
    background: $wds-color-orange;
    color: $wds-color-white;

    &:hover,
    &:active,
    &:focus {
      background: $wds-color-orange-dark;
    }
  }

  &.secondary {
    background: $wds-color-white;
    color: $wds-color-ink-dark;
    box-shadow: inner-border(wds-rem(1px), $wds-color-ink-light);

    &:hover,
    &:active {
      color: $wds-color-orange;
      box-shadow: inner-border(wds-rem(1px), $wds-color-orange);
    }
  }

  &.dark {
    font-weight: bold;
    background: #313131;
    color: $wds-color-orange;
    box-shadow: inner-border(wds-rem(1px), $wds-color-orange);
  }

  &.confirmation {
    font-weight: bold;
    background: $wds-color-green;
    color: $wds-color-white;

    &:hover {
      background: $wds-color-green-dark;
    }
  }

  &.full-width {
    display: block;
    width: 100%;
  }

  &.link {
    background: none;
    font-size: $wds-font_size-body-2;
    color: $wds-color-ink;
  }

  &.disabled {
    opacity: 0.38;
    pointer-events: none;
  }

  &.icon {
    color: inherit;
    outline: none;
    cursor: pointer;
    border: none;
    padding: $wds-spacing-s;
    margin: -$wds-spacing-s;
    border-radius: 50%;
    background: none;
    font-size: inherit;
    display: inline-flex;

    &:hover {
      background: $wds-color-ink-lightest;
    }

    :not(i) {
      display: none;
    }
  }
}
</style>
