import search from '~/config/search';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
import isBefore from 'date-fns/isBefore';
import isValid from 'date-fns/isValid';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import startOfDay from 'date-fns/startOfDay';
import parseISO from 'date-fns/parseISO';

const DAY_MILLISECONDS = 86400000;

/**
 * Format a date in ISO string format YYYY-MM-DD
 * @param {Date} date
 * @return {string}
 */
export const isoDate = (date) => date.toISOString().slice(0, 10);

/**
 * Returns the number of nights between two time instants.
 *
 * @param {String} fromDate
 * @param {String} toDate
 */
export const numberOfNights = (fromDate, toDate) => {
  const to = new Date(toDate);
  const from = new Date(fromDate);
  const difference = to.getTime() - from.getTime();
  return Math.ceil(difference / DAY_MILLISECONDS);
};

/**
 * Returns the number of days from now to the arrival date.
 *
 * @param {Date} arrivalDate
 */
export const leadTime = (arrivalDate) => {
  const arrivalDateISO = parseISO(arrivalDate);
  const today = new Date();

  return differenceInCalendarDays(arrivalDateISO, today);
};

/**
 * Returns the given date if it is a legal checkIn date, or the default checkIn.
 * The default checkIn date is tomorrow from the user's perspective. Default checkIn date is incremented in "incrementDays" days when given.
 *
 * @param {Date} [checkIn]
 * @param {Number} [incrementDays]
 */
export const checkinDate = (checkIn, incrementDays = 0) => {
  const today = startOfDay(Date.now());
  const defaultCheckIn = incrementDays ? addDays(today, parseInt(incrementDays, 10)) : addDays(today, 1);
  // Only coerce to defaultCheckIn where invalid or before yesterday (user may be in TZ that is day before)
  if (!isValid(checkIn) || isBefore(checkIn, addDays(today, -1))) {
    return defaultCheckIn;
  }

  return checkIn;
};

/**
 * Returns the given date if it is a legal checkout date given the checkin date, or a default checkout date.
 * The default checkout date is the checkin date plus the default number of nights, unless the check-in is more
 * than maxNights before in check-out date, then it's the checkin date plus maxNights.
 *
 * @param {Date} checkIn
 * @param {Date} [checkOut]
 * @param {Date} extendSearch
 */
export const checkoutDate = (checkIn, checkOut, extendSearch) => {
  const defaultCheckOut = addDays(checkIn, search.defaultNights);

  if (!isValid(checkOut)) {
    return defaultCheckOut;
  }

  const nights = differenceInDays(checkOut, checkIn);
  // TODO HWPWA-2043 (https://jira.corp.hostelworld.com/browse/HWPWA-2043)
  const maxNights = extendSearch ? search.extendedMaxNights : search.maxNights;

  if (nights < 1) {
    return defaultCheckOut;
  } else if (nights > maxNights) {
    return addDays(checkIn, maxNights);
  }

  return checkOut;
};

/**
 * Format a short human-readable date according to the specified locale.
 *
 * @param {Date} date
 * @param {string} locale
 * @param {Boolean} showWeekday
 * @param {Boolean} showYear
 */

export const formatHumanReadableShortDate = (date, locale, showWeekday = false, showYear = true) => {
  const monthFormat = ['en-US', 'en-GB', 'en'].includes(locale) ? 'short' : 'numeric';
  const options = {
    weekday: showWeekday ? 'short' : undefined,
    month: monthFormat,
    day: '2-digit',
  };
  if (showYear) {
    options.year = 'numeric';
  }
  const format = new Intl.DateTimeFormat(locale, options);
  return format.format(date);
};

/**
 * Difference in months between 2 dates
 *
 * @param {Date} d1
 * @param {Date} d2
 */

export const monthDiff = (d1, d2) => {
  const date1 = d1 < d2 ? d1 : d2;
  const date2 = d1 > d2 ? d1 : d2;
  let months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months += date2.getMonth() - date1.getMonth();
  if (date2.getDate() < date1.getDate()) {
    months--;
  }
  return months;
};
