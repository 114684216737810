<template>
  <div :class="['date-picker', position]">
    <svg-icon
      ref="previousMonth"
      name="chevron-left"
      :class="['icon-move-month', 'move-prev', { disabled: disablePrevious }]"
      @click="disablePrevious ? '' : previousMonth()"
      @keypress.enter="disablePrevious ? '' : previousMonth()"
    />
    <svg-icon
      ref="nextMonth"
      name="chevron-right"
      :class="['icon-move-month', 'move-next', { disabled: disableNext }]"
      @click="disableNext ? '' : nextMonth()"
      @keypress.enter="disableNext ? '' : nextMonth()"
    />

    <calendar
      :key="calendar.month"
      :month="calendar.month"
      :year="calendar.year"
      :selected="selectedDate"
      :disabled-until="disabledDateUntil"
      :disabled-from="disabledDateFrom"
      class="calendar"
      @daySelected="selectDay"
    />
  </div>
</template>

<script>
import Calendar from '~/components/hwrwd/core/Calendar';

/**
 * This component renders a date picker.
 */
export default {
  name: 'DatePicker',
  components: {
    Calendar,
  },
  props: {
    date: {
      type: Object,
      required: true,
    },
    disabledUntil: {
      type: Object,
      required: false,
      default: () => {
        return { year: 0, month: 0, day: 0 };
      },
    },
    disabledFrom: {
      type: Object,
      required: false,
      default: () => {
        return { year: 0, month: 0, day: 0 };
      },
    },
    position: {
      type: String,
      required: true,
      validator: (value) => {
        return ['left', 'right'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      calendar: {},
      disabledDateUntil: this.disabledUntil,
      disabledDateFrom: this.disabledFrom,
      selectedDate: {
        start: this.date,
        end: this.date,
      },
      disablePrevious: false,
      disableNext: false,
    };
  },
  beforeMount() {
    this.calendar = { ...this.date };
    this.disableArrows();
  },
  methods: {
    nextMonth() {
      const selectedDate = { ...this.date };
      this.selectedDate = { start: selectedDate, end: selectedDate };
      this.disabledDateUntil = { ...this.disabledDateUntil };

      if (this.calendar.month === 12) {
        this.calendar.month = 1;
        this.calendar.year++;
        this.disableArrows();

        return;
      }

      this.calendar.month++;
      this.disableArrows();
    },
    previousMonth() {
      const selectedDate = { ...this.calendar };
      this.selectedDate = { start: selectedDate, end: selectedDate };
      this.disabledDateUntil = { ...this.disabledDateUntil };

      if (this.calendar.month === 1) {
        this.calendar.month = 12;
        this.calendar.year--;
        this.disableArrows();

        return;
      }

      this.calendar.month--;
      this.disableArrows();
    },
    selectDay(selection) {
      this.$emit('datePickerChanged', selection);
    },
    disableArrows() {
      if (!this.disabledUntil.month && !this.disabledFrom.month) {
        return;
      }

      this.disablePrevious = false;
      this.disableNext = false;

      if (
        this.disabledUntil &&
        this.disabledUntil.month >= this.calendar.month &&
        this.disabledUntil.year === this.calendar.year
      ) {
        this.disablePrevious = true;
      }

      if (this.calendar.month >= this.disabledFrom.month && this.disabledFrom.year === this.calendar.year) {
        this.disableNext = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.date-picker {
  font-size: $wds-font-size-body-1;
  background-color: $wds-color-white;
  padding: $wds-spacing-m;
  position: absolute;
  top: wds-rem(73px);
  border-radius: wds-rem(4px);
  width: wds-rem(300px);
  height: wds-rem(320px);
  display: grid;
  box-shadow: 0 wds-rem(1px) wds-rem(4px) rgba(0, 0, 0, 0.25);
  z-index: 20;
  margin-top: wds-rem(8px);

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  .icon-move-month {
    width: wds-rem(40px);
    height: $wds-spacing-m;
    fill: $wds-color-ink;
    position: absolute;
    top: 1.6rem;
    transition: fill 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      fill: $wds-color-orange;
    }

    &.disabled {
      fill: $wds-color-ink-lightest;
    }

    &.move-prev {
      left: $wds-spacing-m;
      padding-right: $wds-spacing-m;
    }

    &.move-next {
      right: $wds-spacing-m;
      padding-left: $wds-spacing-m;
    }
  }

  i {
    width: wds-rem(40px);
    font-size: wds-rem(16px);
    position: absolute;
    top: 1.6rem;
    display: flex;
    justify-content: center;
    color: $wds-color-ink;
    cursor: pointer;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: $wds-color-orange;
    }

    &.disabled {
      color: $wds-color-ink-lightest;
    }
  }

  .icon-core-chevron-left {
    left: 1rem;
  }

  .icon-core-chevron-right {
    right: 1rem;
  }
}
</style>
