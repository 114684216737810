import { checkinDate, checkoutDate, formatHumanReadableShortDate } from '~/lib/timeFromPWA';
import { parse, format } from 'date-fns';
import isValid from 'date-fns/isValid';
import { mapActions, mapGetters } from 'vuex';

const today = new Date();

export default {
  data() {
    return {
      displayCheckIn: false,
      displayCheckOut: false,
      today: {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate(),
      },
    };
  },

  computed: {
    checkInDate() {
      const checkIn = this.getSearchCheckIn();
      const dateParsed = parse(`${checkIn.year}-${checkIn.month}-${checkIn.day}`, 'yyyy-MM-dd', new Date());

      return isValid(dateParsed)
        ? formatHumanReadableShortDate(dateParsed, this.$store.state.session.language.tag)
        : '';
    },
    checkOutDate() {
      const checkOut = this.getSearchCheckOut();
      const dateParsed = parse(`${checkOut.year}-${checkOut.month}-${checkOut.day}`, 'yyyy-MM-dd', new Date());

      return isValid(dateParsed)
        ? formatHumanReadableShortDate(dateParsed, this.$store.state.session.language.tag)
        : '';
    },
  },

  created() {
    if (this.getSearchCheckIn().year && this.getSearchCheckOut().year) {
      return;
    }

    const checkin = checkinDate();
    const checkout = checkoutDate(checkin);

    this.setCheckInDate({
      year: checkin.getFullYear(),
      month: checkin.getMonth() + 1,
      day: checkin.getDate(),
    });

    this.setCheckOutDate({
      year: checkout.getFullYear(),
      month: checkout.getMonth() + 1,
      day: checkout.getDate(),
    });
  },

  methods: {
    ...mapGetters({
      getSearchCheckIn: 'searchForm/getSearchCheckIn',
      getSearchCheckOut: 'searchForm/getSearchCheckOut',
      getSearchDisabledUntil: 'searchForm/getSearchDisabledUntil',
      getSearchDisableFrom: 'searchForm/getSearchDisableFrom',
    }),
    ...mapActions({
      setSearchCheckIn: 'searchForm/setSearchCheckIn',
      setSearchCheckOut: 'searchForm/setSearchCheckOut',
    }),
    toggleCheckIn() {
      if (!this.displayCheckIn) {
        this.closeCheckOut();
      }
      this.displayCheckIn = !this.displayCheckIn;
    },
    toggleCheckOut() {
      if (!this.displayCheckOut) {
        this.closeCheckIn();
      }
      this.displayCheckOut = !this.displayCheckOut;
    },
    closeCheckIn() {
      this.displayCheckIn = false;
    },
    closeCheckOut() {
      this.displayCheckOut = false;
    },
    setCheckInDate(event) {
      if (!event.year) {
        return;
      }

      let checkIn = event;
      let checkOut = this.getSearchCheckOut();
      this.displayCheckIn = false;

      const start = new Date(event.year, event.month - 1, event.day);
      const end = checkoutDate(start, new Date(checkOut.year, checkOut.month - 1, checkOut.day));
      checkOut = { year: end.getFullYear(), month: end.getMonth() + 1, day: end.getDate() };

      if (start >= end) {
        const eventDate = new Date(event.year, event.month - 1, event.day);
        const newEnd = new Date(eventDate.setDate(eventDate.getDate() + 1));
        checkOut = { year: newEnd.getFullYear(), month: newEnd.getMonth() + 1, day: newEnd.getDate() };
      }

      this.setSearchCheckIn(checkIn);
      this.setSearchCheckOut(checkOut);
      this.displayCheckOut = true;
    },
    setCheckOutDate(event) {
      if (!event.year) {
        return;
      }

      this.setSearchCheckOut(event);
      this.displayCheckOut = false;
    },
    getCheckInDate() {
      const checkIn = this.getSearchCheckIn();
      return format(new Date(checkIn.year, checkIn.month - 1, checkIn.day), 'yyyy-MM-dd');
    },
    getCheckOutDate() {
      const checkOut = this.getSearchCheckOut();
      return format(new Date(checkOut.year, checkOut.month - 1, checkOut.day), 'yyyy-MM-dd');
    },
  },
};
