import { commonValues } from '~/config/common';

export default (title, image, url, description, type, site_name = commonValues.HOSTELWORLD) => {
  return {
    'og:title': title,
    'og:image': image,
    'og:url': url,
    'og:description': description,
    'og:site_name': site_name,
    'og:type': type,
  };
};
