<template>
  <div class="property-ratings-section">
    <h2>{{ $t('t_REVIEWSANDRATINGS') }}</h2>
    <div class="property-ratings-container">
      <a
        href="#"
        @click.prevent="triggerRatingSummaryClick"
        @keypress.space.prevent="triggerRatingSummaryClick"
        :aria-label="$t('t_REVIEWSANDRATINGS')"
      >
        <rwd-rating-summary v-bind="summaryInfo" />
      </a>
      <div class="property-rating-breakdown">
        <rwd-rating-breakdown v-bind="getPropertyRatingBreakdownData" />
      </div>
    </div>
  </div>
</template>

<script>
import RwdRatingSummary from '~/components/hwrwd/common/Ratings/RatingSummary';
import RwdRatingBreakdown from '~/components/hwrwd/common/Ratings/RatingBreakdown';
import mq from '~/mixins/mq';

export default {
  name: 'RatingSection',
  mixins: [mq],
  components: {
    RwdRatingSummary,
    RwdRatingBreakdown,
  },
  props: {
    summaryInfo: {
      type: Object,
      required: true,
    },
    breakdownInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getPropertyRatingBreakdownData() {
      return {
        breakdown: this.breakdownInfo,
        applyGrid: !this.isBelowMediumScreen,
      };
    },
  },
  methods: {
    triggerRatingSummaryClick() {
      this.$emit('rating-summary-click');
    },
  },
};
</script>

<style lang="scss" scoped>
.property-ratings-section {
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-m;
  padding: $wds-spacing-m;

  h2 {
    color: $wds-color-ink-dark;
    @include title-4-bld;
  }

  .property-ratings-container {
    .property-rating-breakdown {
      margin-top: $wds-spacing-m;
    }
  }
}
</style>
