<template>
  <div class="collapsible">
    <div :id="id && `${id}-title`" class="title">
      <slot name="title" />
    </div>
    <div :id="id && `${id}-content`" :class="['content', { 'collapse-content': collapsed }]" :style="lineClamping">
      <slot name="description" />
    </div>
    <slot v-if="!collapsed" />
    <a
      v-if="showExpandLink"
      class="toggle-content"
      rel="toggle-collapse"
      tabindex="0"
      @click="toggleExpandLink"
      @keyup.enter="toggleExpandLink"
      @keyup.space="toggleExpandLink"
      role="link"
    >
      {{ collapsed ? showMore : showLess }}
    </a>
  </div>
</template>

<script>
/**
 * This component has an issue on IOS, where the show more/less doesn't appear.
 * So, for now, it will show the more/less, then, will hide it, after DOM is ready
 * Ticket to investigate/fix: HWPWA-518
 **/

// margin for offsetHeight to compensate for decimal positions on line-heights
const offsetCompensator = 2;

export default {
  name: 'Collapsible',
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: null,
    },
    /**
     * The text of the show more
     */
    showMore: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * The text of the show less
     */
    showLess: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * The number of lines to be showed before cutting them
     */
    lines: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  data() {
    return {
      collapsed: true,
      showExpandLink: true,
    };
  },
  computed: {
    lineClamping() {
      return `-webkit-line-clamp: ${this.lines}`;
    },
  },
  mounted() {
    // @TODO (HWPWA-518) This should be fixed in order to provide SSR to this component
    this.shouldShowExpandLink();
  },
  methods: {
    /**
     * This checks if the content is bigger than the line clamp, to show or not the 'show more/less' link
     * the variable offsetCompensator is a margin for offsetHeight to compensate for decimal positions on line-heights
     */
    shouldShowExpandLink() {
      const contentElement = this.$el.querySelector('.content');
      this.showExpandLink =
        contentElement && contentElement.offsetHeight + offsetCompensator < contentElement.scrollHeight;
      this.collapsed = this.showExpandLink;
    },
    /**
     * Toggles show more state
     */
    toggleExpandLink() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style lang="scss" scoped>
.collapsible {
  .collapse-content {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .toggle-content {
    display: block;
    text-transform: uppercase;
    font-size: $wds-font-size-body-3;
    color: $wds-color-orange;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
}
</style>
