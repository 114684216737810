var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"calendar",staticClass:"calendar-month"},[_c('div',{staticClass:"month-name"},[_vm._v(_vm._s(_vm.monthName)+" "+_vm._s(_vm.year))]),_vm._v(" "),_vm._l((_vm.weekNames),function(week){return _c('div',{key:week,staticClass:"week-name"},[_vm._v(_vm._s(week.substring(0, 2)))])}),_vm._v(" "),_vm._l((_vm.totalDays),function(day){return _c('div',{key:day,ref:`calendar-day-${day}`,refInFor:true,staticClass:"day",class:{
      today: day && _vm.isToday(day),
      disabled: day && _vm.disabledDay(day),
      start: day && _vm.selectedStartDay(day),
      end: day && _vm.selectedEndDay(day),
      range: day && _vm.dayInRange(day),
      empty: !day,
    },attrs:{"tabindex":day && _vm.isToday(day) ? 0 : -1},on:{"click":function($event){return _vm.dayClicked(day)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return _vm.moveLeft(day)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;return _vm.moveRight(day)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();return _vm.dayClicked(day)}]}},[_c('div',[_vm._v(_vm._s(day))])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }