<template>
  <wds-bread-crumbs
    :breadcrumbs="crumbs"
    :showLastItem="showLastItem"
    :linkOnLastItem="linkOnLastItem"
    :max-crumbs-shown="getCrumbsShownByViewport"
    :reversed="true"
  />
</template>

<script>
import { WdsBreadCrumbs } from '@wds/components';
import mq from '~/mixins/mq';

export default {
  name: 'BreadCrumbs',
  mixins: [mq],
  components: {
    WdsBreadCrumbs,
  },

  props: {
    crumbs: {
      type: Array,
      required: true,
      validator(crumbs) {
        return crumbs.every((crumb) => crumb.link && crumb.title);
      },
    },
    showLastItem: {
      type: Boolean,
      required: false,
      default: true,
    },
    linkOnLastItem: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    getCrumbsShownByViewport() {
      return this.isBelowMediumScreen ? 2 : this.isMediumScreen ? 3 : null;
    },
  },
};
</script>
