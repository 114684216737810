<template>
  <h1 v-if="title" v-html="title"></h1>
</template>

<script>
export default {
  name: 'HeaderTitle',

  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin: 0 0 $wds-spacing-m;
  padding: 0 $wds-spacing-m;
  color: $wds-color-white;
  font-size: $wds-line-height-title-6;
  text-transform: uppercase;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  font-weight: $wds-font-weight-bold;
  letter-spacing: $wds-letter-spacing-title-2;
  line-height: $wds-line-height-title-5;
  -webkit-font-smoothing: antialiased;

  @media screen and (min-width: $screen-medium-large) {
    font-size: $wds-font-size-title-2;
    line-height: $wds-line-height-title-3;
  }
}
</style>
