import { parse } from '~/lib/cookie';

const APP_BANNER_COOKIE_NAME = 'appBannerDismissed';
const APP_BANNER_MAX_DISMISS = 7 * 86400;

export default {
  methods: {
    getAppDownloadBannerVariation(store, page) {
      const appBannerEnabled = store.$optimizely.isFeatureEnabled('web_app_download_banner');
      const appBannerVars = store.$optimizely.getAllFeatureVariables('web_app_download_banner');

      const isAppDownloadBannerEnabledForPage =
        appBannerEnabled && (appBannerVars?.[page] || appBannerVars.staticpages?.[page]);

      if (!isAppDownloadBannerEnabledForPage) {
        return 'control';
      }

      return appBannerVars?.message === 'a' ? 'variation a' : 'variation b';
    },
    isAppDownloadBannerDismissed(req) {
      const cookiesContent = process.server ? parse(req?.headers?.cookie || '') : parse(document.cookie || '');
      const appBannerDismissCookie = cookiesContent[APP_BANNER_COOKIE_NAME];
      if (appBannerDismissCookie) {
        const timestamp = Math.floor(Date.now() / 1000);
        return timestamp - appBannerDismissCookie < APP_BANNER_MAX_DISMISS;
      }

      return false;
    },
  },
};
