var render = function render(_c,_vm){return _c(_vm.props.type,_vm._g(_vm._b({tag:"component",staticClass:"button",class:[
    {
      primary: _vm.props.color === 'primary',
      secondary: _vm.props.color === 'secondary',
      link: _vm.props.color === 'link',
      dark: _vm.props.color === 'dark',
      small: _vm.props.size === 'small',
      large: _vm.props.size === 'large',
      'full-width': _vm.props.fullWidth,
      disabled: _vm.props.disabled,
      icon: _vm.props.icon,
    },
    _vm.data.class,
    _vm.data.staticClass,
  ],attrs:{"disabled":_vm.props.disabled}},'component',_vm.data.attrs,false),_vm.listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }