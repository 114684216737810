<template functional>
  <div :class="['rating-factors', props.size]">
    <div v-if="$options.isFactorGoodEnough(props.ratingBreakdown.location)" class="rating-factor location">
      <svg-icon name="location-pin" class="svg-icon" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span class="rating" v-html="$options.getRatingTranslation(props.ratingBreakdown, parent, 'location')"></span>
    </div>

    <div v-if="$options.isFactorGoodEnough(props.ratingBreakdown.staff)" class="rating-factor staff">
      <svg-icon name="users" class="svg-icon" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span class="rating" v-html="$options.getRatingTranslation(props.ratingBreakdown, parent, 'staff')"></span>
    </div>

    <div v-if="$options.isFactorGoodEnough(props.ratingBreakdown.cleanliness)" class="rating-factor cleanliness">
      <svg-icon name="sparkles" class="svg-icon" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span class="rating" v-html="$options.getRatingTranslation(props.ratingBreakdown, parent, 'cleanliness')"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RatingFactors',
  props: {
    ratingBreakdown: {
      type: Object,
      required: true,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'small',
      validator: (value) => {
        return ['big', 'small'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      factorLabels: {
        location: ['PERFECTLOCATION', 'WONDERFULLOCATION', 'GREATLOCATION', 'NICELOCATION'],
        staff: ['SUPERBSTAFF', 'MARVELOUSSTAFF', 'BRILLIANTSTAFF', 'HELPFULSTAFF'],
        cleanliness: ['FANTASTICCLEANLINESS', 'EXCELLENTCLEANLINESS', 'AWESOMECLEANLINESS', 'GOODCLEANLINESS'],
      },
    };
  },
  isFactorGoodEnough(factorScore) {
    return (factorScore || 0) >= 6;
  },
  getRatingTranslation(ratingBreakdown, parent, factor) {
    const factorLabels = this.data().factorLabels;

    let factorLabelIndex = Math.floor(9 - ratingBreakdown[factor]); // We only rate if above 6, so take rating from the max score 10 (-1 for index) to determine to label
    if (factorLabelIndex < 0) {
      factorLabelIndex = 0;
    }

    return parent.$t(`t_RATINGFACTOR${factorLabels[factor][factorLabelIndex]}`);
  },
};
</script>

<style lang="scss" scoped>
.rating-factors {
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-m;
  border-top: 1px dotted $wds-color-ink-lighter;
  border-bottom: 1px dotted $wds-color-ink-lighter;
  padding: $wds-spacing-m 0;
  @include body-2-reg;

  .rating-factor {
    display: inline-flex;
    gap: $wds-spacing-xs;
    align-items: center;

    .svg-icon {
      width: $wds-spacing-m;
      height: $wds-spacing-m;
      fill: $wds-color-teal-light;
    }
  }

  .rating {
    color: $wds-color-ink-dark;
    text-transform: capitalize;
  }
}

.rating-factors.big {
  .rating-factor {
    display: flex;
    margin: $wds-spacing-m 0 0;
  }
}
</style>
