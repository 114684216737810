export default {
  microsite: {
    token: 'pk.eyJ1IjoiaG9zdGVsd29ybGRwcm9kdWN0IiwiYSI6ImNqc3VmNmRxOTE4MDM0M3M5MGk3czk2ZzIifQ.xsWIVAnUNKLtwrnAfCVvbQ',
    style: 'mapbox/streets-v11',
    zoom: 13,
  },
  fab: {
    token: 'pk.eyJ1IjoiaG9zdGVsd29ybGRwcm9kdWN0IiwiYSI6ImNqdXR1NHBxdDA1cGc0ZW54MWFyOGRpZTQifQ.j9wsue6dC2EVRzS_WUVIPQ',
    style: 'mapbox://styles/hostelworldproduct/clhytyeyp02b901qyg7k68dg8',
  },
  imageApiUrl: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/',
};
