<template>
  <div v-if="propertyAddressInfo.street" class="property-address-container">
    <svg-icon name="location-pin" class="property-address-icon" />
    <div ref="propertyAddressText" class="property-address-text">
      <span v-if="propertyAddressInfo.street">
        <a class="address-link" :href="getLinkToMapOnDynamicMicrosite()" @click.stop @keypress.enter.stop>
          {{ propertyAddressInfo.street }}</a
        >,
      </span>
      <span>
        <a class="address-link" :href="getLinkToCityPage()" @click.stop @keypress.enter.stop>
          {{ propertyAddressInfo.city.name }}</a
        >,
      </span>
      <a class="address-link" :href="getLinkToCountryPage()" @click.stop @keypress.enter.stop>{{
        propertyAddressInfo.country.name
      }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PropertyAddress',

  props: {
    propertyId: {
      type: Number,
      required: true,
    },
    propertyType: {
      type: String,
      required: true,
    },
    propertyFriendlyName: {
      type: String,
      required: true,
    },
    propertyAddressInfo: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getLinkToMapOnDynamicMicrosite() {
      const locationData = {
        urlFriendlyName: this.propertyAddressInfo.city.urlFriendlyName,
      };
      const propertyData = {
        id: this.propertyId,
        urlFriendlyName: this.propertyFriendlyName,
      };
      const extraParams = {
        display: 'map',
      };

      return this.$url.getPwaPropertyDynamicUrl(locationData, propertyData, extraParams);
    },
    getLinkToCityPage() {
      const locationData = {
        propertyTypeSlug: this.propertyType,
        urlFriendlyContinent: this.propertyAddressInfo.continent.urlFriendlyName,
        urlFriendlyCountry: this.propertyAddressInfo.country.urlFriendlyName,
        urlFriendlyCity: this.propertyAddressInfo.city.urlFriendlyName,
      };

      return this.$url.getCityUrl(locationData);
    },
    getLinkToCountryPage() {
      const locationData = {
        propertyTypeSlug: this.propertyType,
        urlFriendlyContinent: this.propertyAddressInfo.continent.urlFriendlyName,
        urlFriendlyCountry: this.propertyAddressInfo.country.urlFriendlyName,
      };

      return this.$url.getCountryPageUrl(locationData);
    },
  },
};
</script>

<style lang="scss" scoped>
.property-address-container {
  display: flex;
  align-items: flex-start;
  color: $wds-color-white;
  font-size: $wds-font-size-body-3;
  flex-grow: 1;

  .property-address-icon {
    margin-right: $wds-spacing-xs;
    margin-top: $wds-spacing-xxs;
    fill: $wds-color-white;
    width: $wds-spacing-m;
    height: $wds-spacing-m;
  }

  .property-address-text {
    display: inline-block;
    flex: 1;

    > span {
      display: inline;
    }

    .address-link {
      display: inline;
      color: $wds-color-white;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
