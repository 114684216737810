<template>
  <wds-section-message
    class="no-availability-message"
    :title="$t('t_NOAVAILABILITYFORDATES', { LOCATION: location })"
    icon="calendar-cancel"
    theme="warning"
    :close-button="false"
  />
</template>

<script>
import { WdsSectionMessage } from '@wds/components';
export default {
  name: 'HwNoAvailabilityMessage',
  components: {
    WdsSectionMessage,
  },
  props: {
    location: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.no-availability-message {
  justify-content: center;

  ::v-deep .item-text {
    margin-right: 0;
  }
}
</style>
